import { Component, OnInit, Inject, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { DanhMucUngDung } from 'src/app/shared/DanhMucUngDung.model';
import { DanhMucUngDungService } from 'src/app/shared/DanhMucUngDung.service';
import { DanhMucTapTinDinhKemPhanLoai } from 'src/app/shared/DanhMucTapTinDinhKemPhanLoai.model';
import { DanhMucTapTinDinhKemPhanLoaiService } from 'src/app/shared/DanhMucTapTinDinhKemPhanLoai.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';

import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';

import { LoHang } from 'src/app/shared/LoHang.model';
import { LoHangService } from 'src/app/shared/LoHang.service';
import { LoHangTapTinDinhKem } from 'src/app/shared/LoHangTapTinDinhKem.model';
import { LoHangTapTinDinhKemService } from 'src/app/shared/LoHangTapTinDinhKem.service';
import { LoHangNhatKy } from 'src/app/shared/LoHangNhatKy.model';
import { LoHangNhatKyService } from 'src/app/shared/LoHangNhatKy.service';
import { LoHangLoTrinh } from 'src/app/shared/LoHangLoTrinh.model';
import { LoHangLoTrinhService } from 'src/app/shared/LoHangLoTrinh.service';
import { LoHangDiemBan } from 'src/app/shared/LoHangDiemBan.model';
import { LoHangDiemBanService } from 'src/app/shared/LoHangDiemBan.service';
import { LoHangBinhChon } from 'src/app/shared/LoHangBinhChon.model';
import { LoHangBinhChonService } from 'src/app/shared/LoHangBinhChon.service';
import { LoHangPhanAnh } from 'src/app/shared/LoHangPhanAnh.model';
import { LoHangPhanAnhService } from 'src/app/shared/LoHangPhanAnh.service';
import { LoHangMuaBan } from 'src/app/shared/LoHangMuaBan.model';
import { LoHangMuaBanService } from 'src/app/shared/LoHangMuaBan.service';

import { TemPhieu } from 'src/app/shared/TemPhieu.model';
import { TemPhieuService } from 'src/app/shared/TemPhieu.service';
import { TemPhieuLichSuTruyCap } from 'src/app/shared/TemPhieuLichSuTruyCap.model';
import { TemPhieuLichSuTruyCapService } from 'src/app/shared/TemPhieuLichSuTruyCap.service';


import { LoHangTapTinDinhKemDetailComponent } from '../lo-hang-tap-tin-dinh-kem-detail/lo-hang-tap-tin-dinh-kem-detail.component';
import { LoHangPhanAnhDetailComponent } from '../lo-hang-phan-anh-detail/lo-hang-phan-anh-detail.component';
import { LoHangBinhChonDetailComponent } from '../lo-hang-binh-chon-detail/lo-hang-binh-chon-detail.component';


import * as maplibregl from 'maplibre-gl';


@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  CSSLoHangBinhChon20: any;
  CSSLoHangBinhChon40: any;
  CSSLoHangBinhChon60: any;
  CSSLoHangBinhChon80: any;
  CSSLoHangBinhChon100: any;
  CSSLoHangBinhChon200: any;

  IsPheDuyet: boolean = false;
  IsHetHanSuDung: boolean = false;
  IsThuHoi: boolean = false;
  IsChuY: boolean = false;

  IsTongHop: boolean = true;
  IsLoHang: boolean = false;
  IsSanPham: boolean = false;
  IsNhaCungCap: boolean = false;
  IsVungNguyenLieu: boolean = false;
  IsNhaMay: boolean = false;
  IsCoSoDongGoi: boolean = false;
  IsKhoLuuTru: boolean = false;
  IsKhoThuongMai: boolean = false;
  IsNhatKy: boolean = false;
  IsLoHangMuaBan: boolean = false;
  IsTapTin: boolean = false;
  IsMap: boolean = false;

  URLVN: string = environment.InitializationString;
  URLCN: string = environment.InitializationString;
  URLEN: string = environment.InitializationString;
  URLJP: string = environment.InitializationString;
  URLKR: string = environment.InitializationString;
  URLFR: string = environment.InitializationString;

  NhaCungCapChuaCapNhat: string = environment.NhaCungCapChuaCapNhat;

  LoHangInfoByQRCode: string = environment.LoHangInfoByQRCode;

  constructor(
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    public router: Router,
    private activeRouter: ActivatedRoute,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DanhMucUngDungService: DanhMucUngDungService,
    public DanhMucTapTinDinhKemPhanLoaiService: DanhMucTapTinDinhKemPhanLoaiService,

    public ThanhVienService: ThanhVienService,
    public ToChucService: ToChucService,

    public LoHangService: LoHangService,
    public LoHangTapTinDinhKemService: LoHangTapTinDinhKemService,
    public LoHangNhatKyService: LoHangNhatKyService,
    public LoHangDiemBanService: LoHangDiemBanService,
    public LoHangLoTrinhService: LoHangLoTrinhService,
    public LoHangBinhChonService: LoHangBinhChonService,
    public LoHangPhanAnhService: LoHangPhanAnhService,
    public LoHangMuaBanService: LoHangMuaBanService,

    public TemPhieuService: TemPhieuService,
    public TemPhieuLichSuTruyCapService: TemPhieuLichSuTruyCapService,

  ) {
    this.ThongBao();
    this.GetCSSLoHangBinhChon();
    this.DanhMucTapTinDinhKemPhanLoaiSearch();    
    localStorage.setItem(environment.QRCode, this.activeRouter.snapshot.params.ID); 
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.TemPhieuService.BaseParameter.QRCode = event.url;       
        this.GetByQueryString();
      }
    });
  }

  ngOnInit(): void {
  }
  ThongBao() {
    this.IsChuY = false;
    this.IsHetHanSuDung = false;
    this.IsPheDuyet = false;
    this.IsThuHoi = false;
  }

  ChangeIsTongHop() {
    this.IsTongHop = !this.IsTongHop;
  }
  ChangeIsLoHang() {
    this.IsLoHang = !this.IsLoHang;
  }
  ChangeIsSanPham() {
    this.IsSanPham = !this.IsSanPham;
  }
  ChangeIsNhaCungCap() {
    this.IsNhaCungCap = !this.IsNhaCungCap;
  }
  ChangeIsVungNguyenLieu() {
    this.IsVungNguyenLieu = !this.IsVungNguyenLieu;
  }
  ChangeIsNhaMay() {
    this.IsNhaMay = !this.IsNhaMay;
  }
  ChangeIsCoSoDongGoi() {
    this.IsCoSoDongGoi = !this.IsCoSoDongGoi;
  }
  ChangeIsKhoLuuTru() {
    this.IsKhoLuuTru = !this.IsKhoLuuTru;
  }
  ChangeIsKhoThuongMai() {
    this.IsKhoThuongMai = !this.IsKhoThuongMai;
  }
  ChangeIsNhatKy() {
    this.IsNhatKy = !this.IsNhatKy;
  }
  ChangeIsLoHangMuaBan() {
    this.IsLoHangMuaBan = !this.IsLoHangMuaBan;
  }
  ChangeIsTapTin() {
    this.IsTapTin = !this.IsTapTin;
  }
  ChangeIsMap() {
    this.IsMap = !this.IsMap;
  }

  DanhMucTapTinDinhKemPhanLoaiSearch() {
    this.TemPhieuService.IsShowLoading = true;
    this.DanhMucTapTinDinhKemPhanLoaiService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucTapTinDinhKemPhanLoaiService.List = res as DanhMucTapTinDinhKemPhanLoai[];
        for (let i = 0; i < this.DanhMucTapTinDinhKemPhanLoaiService.List.length; i++) {
          this.DanhMucTapTinDinhKemPhanLoaiService.List[i].Active = false;
        }
        this.TemPhieuService.IsShowLoading = false;
      },
      err => {
        this.TemPhieuService.IsShowLoading = false;
      }
    );
    this.DanhMucTapTinDinhKemPhanLoaiService.ComponentGetAllToListAsync(this.ToChucService);
  }
  GetCSSLoHangBinhChon() {
    this.CSSLoHangBinhChon20 = 'cursor: pointer; opacity: 0.5;';
    this.CSSLoHangBinhChon20 = this.sanitizer.bypassSecurityTrustStyle(this.CSSLoHangBinhChon20);
    this.CSSLoHangBinhChon40 = 'cursor: pointer; opacity: 0.5;';
    this.CSSLoHangBinhChon40 = this.sanitizer.bypassSecurityTrustStyle(this.CSSLoHangBinhChon40);
    this.CSSLoHangBinhChon60 = 'cursor: pointer; opacity: 0.5;';
    this.CSSLoHangBinhChon60 = this.sanitizer.bypassSecurityTrustStyle(this.CSSLoHangBinhChon60);
    this.CSSLoHangBinhChon80 = 'cursor: pointer; opacity: 0.5;';
    this.CSSLoHangBinhChon80 = this.sanitizer.bypassSecurityTrustStyle(this.CSSLoHangBinhChon80);
    this.CSSLoHangBinhChon100 = 'cursor: pointer; opacity: 0.5;';
    this.CSSLoHangBinhChon100 = this.sanitizer.bypassSecurityTrustStyle(this.CSSLoHangBinhChon100);
    this.CSSLoHangBinhChon200 = 'cursor: pointer; opacity: 0.5;';
    this.CSSLoHangBinhChon200 = this.sanitizer.bypassSecurityTrustStyle(this.CSSLoHangBinhChon200);

  }
  TemPhieuLichSuTruyCapSave() {

    this.TemPhieuLichSuTruyCapService.FormData.ParentID = this.TemPhieuService.FormData.ID;
    this.TemPhieuLichSuTruyCapService.FormData.Code = this.TemPhieuService.FormData.QRCode;
    this.TemPhieuLichSuTruyCapService.FormData.Name = this.TemPhieuService.FormData.MaSo;

    this.TemPhieuLichSuTruyCapService.FormData.IPAddress = localStorage.getItem(environment.IPRegistryIP);

    if ((this.TemPhieuLichSuTruyCapService.FormData.IPAddress == null) || (this.TemPhieuLichSuTruyCapService.FormData.IPAddress.length == 0)) {
      this.DownloadService.GetIPData().then(res => {
        this.TemPhieuLichSuTruyCapService.FormData.IPAddress = res["ip"];
        this.TemPhieuLichSuTruyCapService.FormData.TypeName = res["user_agent"]["device"]["type"];
        this.TemPhieuLichSuTruyCapService.FormData.KinhDo = res["location"]["longitude"];
        this.TemPhieuLichSuTruyCapService.FormData.ViDo = res["location"]["latitude"];
        this.TemPhieuLichSuTruyCapService.FormData.DanhMucQuocGiaName = res["location"]["country"]["name"];
        this.TemPhieuLichSuTruyCapService.FormData.DanhMucTinhThanhName = res["location"]["region"]["name"];

        localStorage.setItem(environment.IPRegistryIP, this.TemPhieuLichSuTruyCapService.FormData.IPAddress);
        localStorage.setItem(environment.IPRegistryDevice, this.TemPhieuLichSuTruyCapService.FormData.TypeName);
        localStorage.setItem(environment.IPRegistryLongitude, this.TemPhieuLichSuTruyCapService.FormData.KinhDo);
        localStorage.setItem(environment.IPRegistryLatitude, this.TemPhieuLichSuTruyCapService.FormData.ViDo);
        localStorage.setItem(environment.IPRegistryCountryName, this.TemPhieuLichSuTruyCapService.FormData.DanhMucQuocGiaName);
        localStorage.setItem(environment.IPRegistryRegionName, this.TemPhieuLichSuTruyCapService.FormData.DanhMucTinhThanhName);

      }).catch(error => {
      }).finally(() => {
        this.TemPhieuLichSuTruyCapService.SaveAsync().subscribe(
          res => {
          },
          err => {
          }
        );
      });
    }
    else {
      this.TemPhieuLichSuTruyCapService.FormData.IPAddress = localStorage.getItem(environment.IPRegistryIP);
      this.TemPhieuLichSuTruyCapService.FormData.TypeName = localStorage.getItem(environment.IPRegistryDevice);
      this.TemPhieuLichSuTruyCapService.FormData.KinhDo = localStorage.getItem(environment.IPRegistryLongitude);
      this.TemPhieuLichSuTruyCapService.FormData.ViDo = localStorage.getItem(environment.IPRegistryLatitude);
      this.TemPhieuLichSuTruyCapService.FormData.DanhMucQuocGiaName = localStorage.getItem(environment.IPRegistryCountryName);
      this.TemPhieuLichSuTruyCapService.FormData.DanhMucTinhThanhName = localStorage.getItem(environment.IPRegistryRegionName);
      this.TemPhieuLichSuTruyCapService.SaveAsync().subscribe(
        res => {
        },
        err => {
        }
      );
    }
  }
  GetByQueryString() {
    this.URLVN = environment.TraceabilitySite + "/#" + this.TemPhieuService.BaseParameter.QRCode;
    this.URLCN = environment.TraceabilityTranslateSite + ".translate.goog/?_x_tr_sl=vi&_x_tr_tl=zh-TW&_x_tr_hl=vi&_x_tr_pto=wapp&_x_tr_hist=true#" + this.TemPhieuService.BaseParameter.QRCode;
    this.URLEN = environment.TraceabilityTranslateSite + ".translate.goog/?_x_tr_sl=vi&_x_tr_tl=en&_x_tr_hl=vi&_x_tr_pto=wapp&_x_tr_hist=true#" + this.TemPhieuService.BaseParameter.QRCode;
    this.URLJP = environment.TraceabilityTranslateSite + ".translate.goog/?_x_tr_sl=vi&_x_tr_tl=ja&_x_tr_hl=vi&_x_tr_pto=wapp&_x_tr_hist=true#" + this.TemPhieuService.BaseParameter.QRCode;
    this.URLKR = environment.TraceabilityTranslateSite + ".translate.goog/?_x_tr_sl=vi&_x_tr_tl=ko&_x_tr_hl=vi&_x_tr_pto=wapp&_x_tr_hist=true#" + this.TemPhieuService.BaseParameter.QRCode;
    this.URLFR = environment.TraceabilityTranslateSite + ".translate.goog/?_x_tr_sl=vi&_x_tr_tl=fr&_x_tr_hl=vi&_x_tr_pto=wapp&_x_tr_hist=true#" + this.TemPhieuService.BaseParameter.QRCode;

    // this.URLVN = "https://traceability.blockchain.carbonunit.net/#" + this.TemPhieuService.BaseParameter.QRCode;
    // this.URLCN = "https://traceability-blockchain-carbonunit-net.translate.goog/?_x_tr_sl=vi&_x_tr_tl=zh-TW&_x_tr_hl=vi&_x_tr_pto=wapp&_x_tr_hist=true#" + this.TemPhieuService.BaseParameter.QRCode;
    // this.URLEN = "https://traceability-blockchain-carbonunit-net.translate.goog/?_x_tr_sl=vi&_x_tr_tl=en&_x_tr_hl=vi&_x_tr_pto=wapp&_x_tr_hist=true#" + this.TemPhieuService.BaseParameter.QRCode;
    // this.URLJP = "https://traceability-blockchain-carbonunit-net.translate.goog/?_x_tr_sl=vi&_x_tr_tl=ja&_x_tr_hl=vi&_x_tr_pto=wapp&_x_tr_hist=true#" + this.TemPhieuService.BaseParameter.QRCode;
    // this.URLKR = "https://traceability-blockchain-carbonunit-net.translate.goog/?_x_tr_sl=vi&_x_tr_tl=ko&_x_tr_hl=vi&_x_tr_pto=wapp&_x_tr_hist=true#" + this.TemPhieuService.BaseParameter.QRCode;
    // this.URLFR = "https://traceability-blockchain-carbonunit-net.translate.goog/?_x_tr_sl=vi&_x_tr_tl=fr&_x_tr_hl=vi&_x_tr_pto=wapp&_x_tr_hist=true#" + this.TemPhieuService.BaseParameter.QRCode;


    this.TemPhieuService.IsShowLoading = true;
    this.TemPhieuService.GetByQRCodeAsync().subscribe(
      res => {
        this.TemPhieuService.FormData = res as TemPhieu;

        let Bearer = this.TemPhieuService.Headers.getAll("Authorization")[0];
        if (Bearer == environment.Bearer) {
          this.TemPhieuService.Headers = new HttpHeaders();
          this.TemPhieuService.Headers = this.TemPhieuService.Headers.append('Authorization', 'Bearer ' + this.TemPhieuService.FormData.Description);
        }

        Bearer = this.LoHangService.Headers.getAll("Authorization")[0];
        if (Bearer == environment.Bearer) {
          this.LoHangService.Headers = new HttpHeaders();
          this.LoHangService.Headers = this.LoHangService.Headers.append('Authorization', 'Bearer ' + this.TemPhieuService.FormData.Description);
        }

        Bearer = this.TemPhieuLichSuTruyCapService.Headers.getAll("Authorization")[0];
        if (Bearer == environment.Bearer) {
          this.TemPhieuLichSuTruyCapService.Headers = new HttpHeaders();
          this.TemPhieuLichSuTruyCapService.Headers = this.TemPhieuLichSuTruyCapService.Headers.append('Authorization', 'Bearer ' + this.TemPhieuService.FormData.Description);
        }

        Bearer = this.DanhMucUngDungService.Headers.getAll("Authorization")[0];
        if (Bearer == environment.Bearer) {
          this.DanhMucUngDungService.Headers = new HttpHeaders();
          this.DanhMucUngDungService.Headers = this.DanhMucUngDungService.Headers.append('Authorization', 'Bearer ' + this.TemPhieuService.FormData.Description);
        }

        Bearer = this.ToChucService.Headers.getAll("Authorization")[0];
        if (Bearer == environment.Bearer) {
          this.ToChucService.Headers = new HttpHeaders();
          this.ToChucService.Headers = this.ToChucService.Headers.append('Authorization', 'Bearer ' + this.TemPhieuService.FormData.Description);
        }

        Bearer = this.LoHangTapTinDinhKemService.Headers.getAll("Authorization")[0];
        if (Bearer == environment.Bearer) {
          this.LoHangTapTinDinhKemService.Headers = new HttpHeaders();
          this.LoHangTapTinDinhKemService.Headers = this.LoHangTapTinDinhKemService.Headers.append('Authorization', 'Bearer ' + this.TemPhieuService.FormData.Description);
        }

        Bearer = this.LoHangNhatKyService.Headers.getAll("Authorization")[0];
        if (Bearer == environment.Bearer) {
          this.LoHangNhatKyService.Headers = new HttpHeaders();
          this.LoHangNhatKyService.Headers = this.LoHangNhatKyService.Headers.append('Authorization', 'Bearer ' + this.TemPhieuService.FormData.Description);
        }


        if (this.TemPhieuService.FormData) {
          if (this.TemPhieuService.FormData.ID > 0) {
            if (this.TemPhieuService.FormData.Active == true) {

              if (this.TemPhieuService.FormData.SoLanKiemTra == null) {
                this.TemPhieuService.FormData.SoLanKiemTra = 0;
              }
              this.TemPhieuService.FormData.SoLanKiemTra = this.TemPhieuService.FormData.SoLanKiemTra + 1;
              this.TemPhieuService.IsShowLoading = true;
              this.TemPhieuService.SaveAsync().subscribe(
                res => {
                  this.TemPhieuService.FormData = res as TemPhieu;
                  this.TemPhieuService.IsShowLoading = false;
                },
                err => {
                  this.TemPhieuService.IsShowLoading = false;
                }
              );

              this.TemPhieuLichSuTruyCapSave();

              this.LoHangService.BaseParameter.ID = this.TemPhieuService.FormData.ParentID;
              this.LoHangService.GetByIDAsync().subscribe(
                res => {
                  this.LoHangService.FormData = res as LoHang;
                  this.TemPhieuService.IsShowLoading = false;

                  this.LoHangService.BaseParameter.TypeName = this.LoHangService.FormData.TypeName;
                  this.LoHangService.BaseParameter.Active = true;
                  this.LoHangService.GetByTypeNameAndActiveAsync().subscribe(
                    res => {
                      this.LoHangService.FormData = res as LoHang;

                      if (this.TemPhieuService.FormData.MaSo == environment.MaSo) {
                        this.ThongBao();
                        this.IsChuY = true;
                      }
                      else {
                        if (this.LoHangService.FormData.IsPheDuyet == true) {
                          this.ThongBao();
                          this.IsPheDuyet = this.LoHangService.FormData.IsPheDuyet;
                        }
                        if (this.LoHangService.FormData.IsThuHoi == true) {
                          this.ThongBao();
                          this.IsThuHoi = this.LoHangService.FormData.IsThuHoi;
                        }
                      }

                      this.TemPhieuService.IsShowLoading = true;
                      this.DanhMucUngDungService.BaseParameter.ID = this.LoHangService.FormData.DanhMucUngDungID;
                      this.DanhMucUngDungService.GetByIDAsync().subscribe(
                        res => {
                          this.DanhMucUngDungService.FormData = res as DanhMucUngDung;
                          this.TemPhieuService.IsShowLoading = false;
                        },
                        err => {
                          this.TemPhieuService.IsShowLoading = false;
                        }
                      );

                      this.TemPhieuService.IsShowLoading = true;
                      this.ToChucService.BaseParameter.ID = this.LoHangService.FormData.ParentID;
                      this.ToChucService.GetByIDAsync().subscribe(
                        res => {
                          this.ToChucService.FormData = res as ToChuc;
                          this.TemPhieuService.IsShowLoading = false;
                        },
                        err => {
                          this.TemPhieuService.IsShowLoading = false;
                        }
                      );

                      this.TemPhieuService.IsShowLoading = true;
                      this.LoHangTapTinDinhKemService.BaseParameter.ParentID = this.LoHangService.FormData.ID;
                      this.LoHangTapTinDinhKemService.GetByParentIDToListAsync().subscribe(
                        res => {
                          this.LoHangTapTinDinhKemService.List = res as LoHangTapTinDinhKem[];
                          this.LoHangTapTinDinhKemService.List001 = [];
                          this.LoHangTapTinDinhKemService.List002 = [];
                          for (let i = 0; i < this.LoHangTapTinDinhKemService.List.length; i++) {
                            let LoHangTapTinDinhKem = this.LoHangTapTinDinhKemService.List[i];
                            if (LoHangTapTinDinhKem.DanhMucTapTinDinhKemPhanLoaiID == 1) {
                              this.LoHangTapTinDinhKemService.List001.push(LoHangTapTinDinhKem);
                            }
                            if (LoHangTapTinDinhKem.DanhMucTapTinDinhKemPhanLoaiID == 4) {
                              this.LoHangTapTinDinhKemService.List002.push(LoHangTapTinDinhKem);
                            }
                            if (LoHangTapTinDinhKem.DanhMucTapTinDinhKemPhanLoaiID == 5) {
                              this.LoHangTapTinDinhKemService.List002.push(LoHangTapTinDinhKem);
                            }
                            if (LoHangTapTinDinhKem.DanhMucTapTinDinhKemPhanLoaiID == 6) {
                              this.LoHangTapTinDinhKemService.List002.push(LoHangTapTinDinhKem);
                            }
                            if (LoHangTapTinDinhKem.DanhMucTapTinDinhKemPhanLoaiID == 7) {
                              this.LoHangTapTinDinhKemService.List002.push(LoHangTapTinDinhKem);
                            }
                          }
                          this.TemPhieuService.IsShowLoading = false;
                        },
                        err => {
                          this.TemPhieuService.IsShowLoading = false;
                        }
                      );

                      this.TemPhieuService.IsShowLoading = true;
                      this.LoHangNhatKyService.BaseParameter.ParentID = this.LoHangService.FormData.ID;
                      this.LoHangNhatKyService.GetByParentIDToListAsync().subscribe(
                        res => {
                          this.LoHangNhatKyService.List = (res as LoHangNhatKy[]).sort((a, b) => (a.NgayGhiNhan > b.NgayGhiNhan ? 1 : -1));;
                          if (this.LoHangNhatKyService.List.length > 0) {
                            this.LoHangNhatKyService.FormData = this.LoHangNhatKyService.List[0];
                            this.LoHangNhatKyService.FormData001 = this.LoHangNhatKyService.List[this.LoHangNhatKyService.List.length - 1];
                          }
                          for (let i = 0; i < this.LoHangNhatKyService.List.length; i++) {
                            this.LoHangNhatKyService.List[i].Active = false;
                          }
                          this.TemPhieuService.IsShowLoading = false;
                        },
                        err => {
                          this.TemPhieuService.IsShowLoading = false;
                        }
                      );

                      this.TemPhieuService.IsShowLoading = true;
                      this.LoHangMuaBanService.BaseParameter.ParentID = this.LoHangService.FormData.ID;
                      this.LoHangMuaBanService.GetByParentIDToListAsync().subscribe(
                        res => {
                          this.LoHangMuaBanService.List = (res as LoHangMuaBan[]).sort((a, b) => (a.NgayGhiNhan > b.NgayGhiNhan ? 1 : -1));;
                          this.TemPhieuService.IsShowLoading = false;
                        },
                        err => {
                          this.TemPhieuService.IsShowLoading = false;
                        }
                      );
                    },
                    err => {
                      this.TemPhieuService.IsShowLoading = false;
                    }
                  );

                },
                err => {
                  this.TemPhieuService.IsShowLoading = false;
                }
              );

            }
            else {
              this.router.navigate(['/' + environment.Empty]);
            }
          }
          else {
            this.router.navigate(['/' + environment.Empty]);
          }
        }
        else {
          this.router.navigate(['/' + environment.Empty]);
        }


        this.TemPhieuService.IsShowLoading = false;
      },
      err => {
        this.router.navigate(['/' + environment.Empty]);
        this.TemPhieuService.IsShowLoading = false;
      }
    );
  }
  LoHangTapTinDinhKemAdd(ID: number) {
    this.TemPhieuService.IsShowLoading = true;
    this.LoHangTapTinDinhKemService.BaseParameter.ID = ID;
    this.LoHangTapTinDinhKemService.GetByIDAsync().subscribe(
      res => {
        this.LoHangTapTinDinhKemService.FormData = res as LoHangTapTinDinhKem;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(LoHangTapTinDinhKemDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
        this.TemPhieuService.IsShowLoading = false;
      },
      err => {
        this.TemPhieuService.IsShowLoading = false;
      }
    );
  }
  LoHangPhanAnhAdd(ID: number) {
    this.TemPhieuService.IsShowLoading = true;
    this.LoHangPhanAnhService.BaseParameter.ID = ID;
    this.LoHangPhanAnhService.GetByIDAsync().subscribe(
      res => {
        this.LoHangPhanAnhService.FormData = res as LoHangPhanAnh;
        this.LoHangPhanAnhService.FormData.ParentID = this.TemPhieuService.FormData.ID;
        this.LoHangPhanAnhService.FormData.ParentName = this.TemPhieuService.FormData.QRCode;
        this.LoHangPhanAnhService.FormData.Code = this.TemPhieuService.FormData.MaSo;
        this.LoHangPhanAnhService.FormData.Name = this.LoHangService.FormData.DanhMucSanPhamName;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(LoHangPhanAnhDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
        this.TemPhieuService.IsShowLoading = false;
      },
      err => {
        this.TemPhieuService.IsShowLoading = false;
      }
    );
  }
  ToChucAdd(ID: number) {
    window.open(this.LoHangService.FormData.Website, "_blank");
  }
  LoHangBinhChonAdd(ID: number) {
    this.GetCSSLoHangBinhChon();
    if (ID == 20) {
      this.CSSLoHangBinhChon20 = 'cursor: pointer; opacity: 1;';
      this.CSSLoHangBinhChon20 = this.sanitizer.bypassSecurityTrustStyle(this.CSSLoHangBinhChon20);
    }
    if (ID == 40) {
      this.CSSLoHangBinhChon40 = 'cursor: pointer; opacity: 1;';
      this.CSSLoHangBinhChon40 = this.sanitizer.bypassSecurityTrustStyle(this.CSSLoHangBinhChon40);
    }
    if (ID == 60) {
      this.CSSLoHangBinhChon60 = 'cursor: pointer; opacity: 1;';
      this.CSSLoHangBinhChon60 = this.sanitizer.bypassSecurityTrustStyle(this.CSSLoHangBinhChon60);
    }
    if (ID == 80) {
      this.CSSLoHangBinhChon80 = 'cursor: pointer; opacity: 1;';
      this.CSSLoHangBinhChon80 = this.sanitizer.bypassSecurityTrustStyle(this.CSSLoHangBinhChon80);
    }
    if (ID == 100) {
      this.CSSLoHangBinhChon100 = 'cursor: pointer; opacity: 1;';
      this.CSSLoHangBinhChon100 = this.sanitizer.bypassSecurityTrustStyle(this.CSSLoHangBinhChon100);
    }
    if (ID == 200) {
      this.CSSLoHangBinhChon200 = 'cursor: pointer; opacity: 1;';
      this.CSSLoHangBinhChon200 = this.sanitizer.bypassSecurityTrustStyle(this.CSSLoHangBinhChon200);
    }

    this.TemPhieuService.IsShowLoading = true;
    this.LoHangBinhChonService.BaseParameter.ID = ID;
    this.LoHangBinhChonService.GetByIDAsync().subscribe(
      res => {
        this.LoHangBinhChonService.FormData = res as LoHangBinhChon;
        this.LoHangBinhChonService.FormData.ParentID = this.TemPhieuService.FormData.ID;
        this.LoHangBinhChonService.FormData.ParentName = this.TemPhieuService.FormData.QRCode;
        this.LoHangBinhChonService.FormData.Code = this.TemPhieuService.FormData.MaSo;
        this.LoHangBinhChonService.FormData.Name = this.LoHangService.FormData.DanhMucSanPhamName;
        this.LoHangBinhChonService.FormData.SortOrder = ID;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(LoHangBinhChonDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
        this.TemPhieuService.IsShowLoading = false;
      },
      err => {
        this.TemPhieuService.IsShowLoading = false;
      }
    );

  }

  DanhMucTapTinDinhKemPhanLoaiAdd(DanhMucTapTinDinhKemPhanLoai: DanhMucTapTinDinhKemPhanLoai) {
    for (let i = 0; i < this.DanhMucTapTinDinhKemPhanLoaiService.List.length; i++) {
      if (this.DanhMucTapTinDinhKemPhanLoaiService.List[i].ID != DanhMucTapTinDinhKemPhanLoai.ID) {
        this.DanhMucTapTinDinhKemPhanLoaiService.List[i].Active = false;
      }
    }
    DanhMucTapTinDinhKemPhanLoai.Active = !DanhMucTapTinDinhKemPhanLoai.Active;
    this.LoHangTapTinDinhKemService.ListFilter = [];
    for (let i = 0; i < this.LoHangTapTinDinhKemService.List.length; i++) {
      let LoHangTapTinDinhKem = this.LoHangTapTinDinhKemService.List[i];
      if (LoHangTapTinDinhKem.DanhMucTapTinDinhKemPhanLoaiID == DanhMucTapTinDinhKemPhanLoai.ID) {
        this.LoHangTapTinDinhKemService.ListFilter.push(LoHangTapTinDinhKem);
      }
    }
  }
  LoHangNhatKyAdd(LoHangNhatKy: LoHangNhatKy) {
    for (let i = 0; i < this.LoHangNhatKyService.List.length; i++) {
      if (this.LoHangNhatKyService.List[i].ID != LoHangNhatKy.ID) {
        this.LoHangNhatKyService.List[i].Active = false;
      }
    }
    LoHangNhatKy.Active = !LoHangNhatKy.Active;
  }
  TemPhieuSearch001() {
    if (this.TemPhieuService.BaseParameter.MaSo) {
      if (this.TemPhieuService.BaseParameter.MaSo.length > 0) {
        this.TemPhieuService.IsShowLoading = true;
        this.TemPhieuService.GetByMaSoAsync().subscribe(
          res => {
            this.TemPhieuService.FormData = res as TemPhieu;
            if (this.TemPhieuService.FormData.URL) {
              if (this.TemPhieuService.FormData.URL.length > 0) {
                window.location.href = this.TemPhieuService.FormData.URL;
              }
              else {
                this.router.navigate(['/' + environment.Empty]);
              }
            }
            else {
              this.router.navigate(['/' + environment.Empty]);
            }
            this.TemPhieuService.IsShowLoading = false;
          },
          err => {
            this.TemPhieuService.IsShowLoading = false;
          }
        );
      }
    }
  }
  TemPhieuSearch002() {
    if (this.TemPhieuService.BaseParameter.VanChuyenGTIN) {
      if (this.TemPhieuService.BaseParameter.VanChuyenGTIN.length > 0) {
        this.TemPhieuService.IsShowLoading = true;
        this.TemPhieuService.GetByVanChuyenGTINAsync().subscribe(
          res => {
            this.TemPhieuService.FormData = res as TemPhieu;
            if (this.TemPhieuService.FormData.URL) {
              if (this.TemPhieuService.FormData.URL.length > 0) {
                window.location.href = this.TemPhieuService.FormData.URL;
              }
              else {
                this.router.navigate(['/' + environment.Empty]);
              }
            }
            else {
              this.router.navigate(['/' + environment.Empty]);
            }
            this.TemPhieuService.IsShowLoading = false;
          },
          err => {
            this.TemPhieuService.IsShowLoading = false;
          }
        );
      }
    }
  }
  TemPhieuSearch003() {
    if (this.TemPhieuService.BaseParameter.MaLoHang) {
      if (this.TemPhieuService.BaseParameter.MaLoHang.length > 0) {
        this.TemPhieuService.IsShowLoading = true;
        this.TemPhieuService.GetByMaLoHangAsync().subscribe(
          res => {
            this.TemPhieuService.FormData = res as TemPhieu;
            if (this.TemPhieuService.FormData.URL) {
              if (this.TemPhieuService.FormData.URL.length > 0) {
                window.location.href = this.TemPhieuService.FormData.URL;
                //window.open(this.TemPhieuService.FormData.URL, "_blank");
              }
              else {
                this.router.navigate(['/' + environment.Empty]);
              }
            }
            else {
              this.router.navigate(['/' + environment.Empty]);
            }
            this.TemPhieuService.IsShowLoading = false;
          },
          err => {
            this.TemPhieuService.IsShowLoading = false;
          }
        );
      }
    }
  }

  map: maplibregl.Map | undefined;

  @ViewChild('map')
  private mapContainer!: ElementRef<HTMLElement>;

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.map?.remove();
  }

  MapInitialization(longitude, latitude) {
    let IDDate = new Date().toISOString();
    let zoom = 12;

    if ((latitude > 90) || (latitude == 0)) {
      latitude = environment.Latitude;
      longitude = environment.Longitude;
    }
    this.map = new maplibregl.Map({
      container: this.mapContainer.nativeElement,
      style: 'https://api.maptiler.com/maps/hybrid/style.json?key=' + environment.MaptilerAPIKey,
      center: [longitude, latitude],
      zoom: zoom,
    });

    this.map.addControl(
      new maplibregl.NavigationControl({
        visualizePitch: true,
        showZoom: true,
        showCompass: true
      })
    );
    this.map.addControl(
      new maplibregl.FullscreenControl({
      })
    );
    this.map.on('load', () => {

      this.map.addSource("HoangSa" + IDDate, {
        "type": "image",
        "url": environment.DomainURL + "assets/image/HoangSa01.png",
        "coordinates": [
          [111.09665858054495, 17.432475898867523],
          [113.11720985517763, 17.38420482529338],
          [112.79285037220984, 15.643938718432054],
          [110.88537855035554, 15.672592116966598],
        ]
      });
      this.map.addLayer({
        "id": "HoangSa" + IDDate,
        "source": "HoangSa" + IDDate,
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

      this.map.addSource("TruongSa" + IDDate, {
        "type": "image",
        "url": environment.DomainURL + "assets/image/TruongSa01.png",
        "coordinates": [
          [112.32373278444106, 12.236103169381323],
          [117.4620551483049, 11.606334626304161],
          [115.59654957671216, 7.357025445897818],
          [110.62186805246108, 7.811210355974268],


        ]
      });
      this.map.addLayer({
        "id": "TruongSa" + IDDate,
        "source": "TruongSa" + IDDate,
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

    });
  }

  MapLoad(ID: number) {

    this.TemPhieuService.IsShowLoading = true;
    this.LoHangService.BaseParameter.ID = this.TemPhieuService.FormData.ParentID;
    this.LoHangService.GetByIDAsync().subscribe(
      res => {
        this.LoHangService.FormData = res as LoHang;
        if (this.LoHangService.FormData) {
          let latitude = environment.Latitude;
          let longitude = environment.Longitude;

          if (this.LoHangService.FormData.VungNguyenLieuKinhDo) {
            if (this.LoHangService.FormData.VungNguyenLieuViDo) {
              latitude = Number(this.LoHangService.FormData.VungNguyenLieuViDo);
              longitude = Number(this.LoHangService.FormData.VungNguyenLieuKinhDo);
            }
          }

          this.MapInitialization(longitude, latitude);
          if ((ID == 0) || (ID == 4)) {
            if (this.LoHangService.FormData.VungNguyenLieuKinhDo) {
              if (this.LoHangService.FormData.VungNguyenLieuViDo) {
                let popupContent = "<div style='opacity: 0.8; text-align: center; background-color: transparent;'>";
                popupContent = popupContent + "<h1>" + this.LoHangService.FormData.VungNguyenLieuMaSoVungTrong + "</h1>";
                popupContent = popupContent + "</div>";

                let popup = new maplibregl.Popup({ offset: 25 }).setHTML(popupContent)
                  .setMaxWidth("600px");

                var el = document.createElement('div');
                el.style.backgroundImage = "url(" + environment.DomainURL + "assets/image/logo30.png)";
                el.style.width = '30px';
                el.style.height = '30px';

                let marker = new maplibregl.Marker({ element: el })
                  .setLngLat([Number(this.LoHangService.FormData.VungNguyenLieuKinhDo), Number(this.LoHangService.FormData.VungNguyenLieuViDo)])
                  .setPopup(popup)
                  .addTo(this.map);
              }
            }
          }
          if ((ID == 0) || (ID == 2)) {
            if (this.LoHangService.FormData.NhaMayCheBienKinhDo) {
              if (this.LoHangService.FormData.NhaMayCheBienViDo) {
                let popupContent = "<div style='opacity: 0.8; text-align: center; background-color: transparent;'>";
                popupContent = popupContent + "<h1>" + this.LoHangService.FormData.NhaMayCheBienName + "</h1>";
                popupContent = popupContent + "</div>";

                let popup = new maplibregl.Popup({ offset: 25 }).setHTML(popupContent)
                  .setMaxWidth("600px");

                var el = document.createElement('div');
                el.style.backgroundImage = "url(" + environment.DomainURL + "assets/image/logo30.png)";
                el.style.width = '30px';
                el.style.height = '30px';

                let marker = new maplibregl.Marker({ element: el })
                  .setLngLat([Number(this.LoHangService.FormData.NhaMayCheBienKinhDo), Number(this.LoHangService.FormData.NhaMayCheBienViDo)])
                  .setPopup(popup)
                  .addTo(this.map);
              }
            }
            if (this.LoHangService.FormData.CoSoDongGoiKinhDo) {
              if (this.LoHangService.FormData.CoSoDongGoiViDo) {
                let popupContent = "<div style='opacity: 0.8; text-align: center; background-color: transparent;'>";
                popupContent = popupContent + "<h1>" + this.LoHangService.FormData.CoSoDongGoiName + "</h1>";
                popupContent = popupContent + "</div>";

                let popup = new maplibregl.Popup({ offset: 25 }).setHTML(popupContent)
                  .setMaxWidth("600px");

                var el = document.createElement('div');
                el.style.backgroundImage = "url(" + environment.DomainURL + "assets/image/logo30.png)";
                el.style.width = '30px';
                el.style.height = '30px';

                let marker = new maplibregl.Marker({ element: el })
                  .setLngLat([Number(this.LoHangService.FormData.CoSoDongGoiKinhDo), Number(this.LoHangService.FormData.CoSoDongGoiViDo)])
                  .setPopup(popup)
                  .addTo(this.map);
              }
            }
            if (this.LoHangService.FormData.KhoLuuTruKinhDo) {
              if (this.LoHangService.FormData.KhoLuuTruViDo) {
                let popupContent = "<div style='opacity: 0.8; text-align: center; background-color: transparent;'>";
                popupContent = popupContent + "<h1>" + this.LoHangService.FormData.KhoLuuTruName + "</h1>";
                popupContent = popupContent + "</div>";

                let popup = new maplibregl.Popup({ offset: 25 }).setHTML(popupContent)
                  .setMaxWidth("600px");

                var el = document.createElement('div');
                el.style.backgroundImage = "url(" + environment.DomainURL + "assets/image/logo30.png)";
                el.style.width = '30px';
                el.style.height = '30px';

                let marker = new maplibregl.Marker({ element: el })
                  .setLngLat([Number(this.LoHangService.FormData.KhoLuuTruKinhDo), Number(this.LoHangService.FormData.KhoLuuTruViDo)])
                  .setPopup(popup)
                  .addTo(this.map);
              }
            }
            if (this.LoHangService.FormData.KhoThuongMaiKinhDo) {
              if (this.LoHangService.FormData.KhoThuongMaiViDo) {
                let popupContent = "<div style='opacity: 0.8; text-align: center; background-color: transparent;'>";
                popupContent = popupContent + "<h1>" + this.LoHangService.FormData.KhoThuongMaiName + "</h1>";
                popupContent = popupContent + "</div>";

                let popup = new maplibregl.Popup({ offset: 25 }).setHTML(popupContent)
                  .setMaxWidth("600px");

                var el = document.createElement('div');
                el.style.backgroundImage = "url(" + environment.DomainURL + "assets/image/logo30.png)";
                el.style.width = '30px';
                el.style.height = '30px';

                let marker = new maplibregl.Marker({ element: el })
                  .setLngLat([Number(this.LoHangService.FormData.KhoThuongMaiKinhDo), Number(this.LoHangService.FormData.KhoThuongMaiViDo)])
                  .setPopup(popup)
                  .addTo(this.map);
              }
            }
          }
          if ((ID == 0) || (ID == 1)) {
            this.LoHangLoTrinhService.BaseParameter.ParentID = this.LoHangService.FormData.ID;
            this.LoHangLoTrinhService.GetByParentIDToListAsync().subscribe(
              res => {
                this.LoHangLoTrinhService.List = res as LoHangLoTrinh[];
                for (let i = 0; i < this.LoHangLoTrinhService.List.length; i++) {
                  let LoHangLoTrinh = this.LoHangLoTrinhService.List[i];
                  if (LoHangLoTrinh.KinhDo) {
                    if (LoHangLoTrinh.ViDo) {
                      let popupContent = "<div style='opacity: 0.8; text-align: center; background-color: transparent;'>";
                      popupContent = popupContent + "<h1>" + LoHangLoTrinh.Name + "</h1>";
                      popupContent = popupContent + "</div>";

                      let popup = new maplibregl.Popup({ offset: 25 }).setHTML(popupContent)
                        .setMaxWidth("600px");

                      var el = document.createElement('div');
                      el.style.backgroundImage = "url(" + environment.DomainURL + "assets/image/logo30.png)";
                      el.style.width = '30px';
                      el.style.height = '30px';

                      let marker = new maplibregl.Marker({ element: el })
                        .setLngLat([Number(LoHangLoTrinh.KinhDo), Number(LoHangLoTrinh.ViDo)])
                        .setPopup(popup)
                        .addTo(this.map);
                    }
                  }
                }
              },
              err => {
              }
            );
          }
          if ((ID == 0) || (ID == 3)) {
            this.LoHangDiemBanService.BaseParameter.ParentID = this.LoHangService.FormData.ID;
            this.LoHangDiemBanService.GetByParentIDToListAsync().subscribe(
              res => {
                this.LoHangDiemBanService.List = res as LoHangDiemBan[];
                for (let i = 0; i < this.LoHangDiemBanService.List.length; i++) {
                  let LoHangDiemBan = this.LoHangDiemBanService.List[i];
                  if (LoHangDiemBan.KinhDo) {
                    if (LoHangDiemBan.ViDo) {
                      let popupContent = "<div style='opacity: 0.8; text-align: center; background-color: transparent;'>";
                      popupContent = popupContent + "<h1>" + LoHangDiemBan.Name + "</h1>";
                      popupContent = popupContent + "</div>";

                      let popup = new maplibregl.Popup({ offset: 25 }).setHTML(popupContent)
                        .setMaxWidth("600px");

                      var el = document.createElement('div');
                      el.style.backgroundImage = "url(" + environment.DomainURL + "assets/image/logo30.png)";
                      el.style.width = '30px';
                      el.style.height = '30px';

                      let marker = new maplibregl.Marker({ element: el })
                        .setLngLat([Number(LoHangDiemBan.KinhDo), Number(LoHangDiemBan.ViDo)])
                        .setPopup(popup)
                        .addTo(this.map);
                    }
                  }
                }
              },
              err => {
              }
            );
          }
        }
        this.TemPhieuService.IsShowLoading = false;
      },
      err => {
        this.TemPhieuService.IsShowLoading = false;
      }
    );
  }
}
