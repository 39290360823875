<div class="container">
    <div class="row">
        <div class="col-lg-2 col-sm-12 col-12">
            <br />
            <img src="assets/image/logo.png" class=""
                style="display: block; margin-left: auto; margin-right: auto; width: 50%;" />
        </div>
        <div class="col-lg-10 col-sm-12 col-12">
            <br />
            <h3 class="text-center mt-3" style="color: #208e1e;"><b>Cổng Truy Xuất Nguồn Gốc Hàng Hóa và Carbon
                    Footprint</b></h3>
            <a title="Đăng nhập để khai báo thông tin" target="_blank"
                href="{{DanhMucUngDungService.FormData.Code}}{{LoHangInfoByQRCode}}/{{TemPhieuService.BaseParameter.QRCode}}">
                <h5 class="text-center" style="color: #30897f;">Đăng nhập để khai báo thông tin</h5>
            </a>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="badge bg-danger-subtle border border-danger-subtle rounded-pill" style="padding-top: 10px;">
            <h6 class="text-center" style="color: #000000;">Dữ liệu không tồn tại. Vui lòng thử lại.
            </h6>
        </div>
    </div>
    <hr />
    <div class="row"
        style="border-color: #e4e4e4; border-style: solid; border-width: 1px; margin: 0px; padding-bottom: 20px;">
        <div class="col-lg-12 col-sm-12 col-12 bg-primary text-light" style="cursor: pointer;">
            <h5 class="text-center" style="padding: 5px; padding-top: 12px;">
                Tra cứu Mã Truy Xuất Nguồn Gốc Hàng Hóa và Carbon
                Footprint
            </h5>
        </div>
        <div class="col-lg-4 col-sm-12 col-12">
            <br />
            <div class="form-group">
                <input name="MaSo" [(ngModel)]="TemPhieuService.BaseParameter.MaSo"
                    placeholder="Nhập Mã số truy vết sản phẩm ..." type="text" class="form-control">
            </div>
            <br />
            <div class="form-group">
                <a (click)="TemPhieuSearch001()" style="width: 100%; color: #ffffff;" title="Mã số truy vết sản phẩm"
                    class="btn btn-primary"><i class="bi bi-search"></i> Tra cứu Mã số truy vết sản phẩm</a>
            </div>
        </div>
        <div class="col-lg-4 col-sm-12 col-12">
            <br />
            <div class="form-group">
                <input name="VanChuyenGTIN" [(ngModel)]="TemPhieuService.BaseParameter.VanChuyenGTIN"
                    placeholder="Nhập Mã GTIN ..." type="text" class="form-control">
            </div>
            <br />
            <div class="form-group">
                <a (click)="TemPhieuSearch002()" style="width: 100%; color: #ffffff;" title="Mã GTIN"
                    class="btn btn-primary"><i class="bi bi-search"></i> Tra cứu Mã GTIN</a>
            </div>
        </div>
        <div class="col-lg-4 col-sm-12 col-12">
            <br />
            <div class="form-group">
                <input name="MaLoHang" [(ngModel)]="TemPhieuService.BaseParameter.MaLoHang"
                    placeholder="Nhập Mã lô đóng gói hàng hóa ..." type="text" class="form-control">
            </div>
            <br />
            <div class="form-group">
                <a (click)="TemPhieuSearch003()" style="width: 100%; color: #ffffff;" title="Mã lô đóng gói hàng hóa"
                    class="btn btn-primary"><i class="bi bi-search"></i> Tra cứu Mã lô đóng gói hàng hóa</a>
            </div>
        </div>
    </div>
</div>
<footer class="bg-success" style="padding: 10px; padding-top: 20px;">
    <h6 class="text-center text-light">Hệ thống truy xuất nguồn gốc và vết chân Carbon ứng dụng Blockchain & AI
    </h6>
    <h6 class="text-center text-light">
        <a href="https://carbonunit.net/" title="https://carbonunit.net/" target="_blank"
            style="text-decoration: none; color:aqua;">Carbon Footprint Traceability System @ 2VN & CFT by Digital
            Kingdom JSC</a>
    </h6>
</footer>