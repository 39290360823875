<div class="container">
    <div class="row">
        <div class="col-lg-2 col-sm-12 col-12">
            <br />
            <img src="{{DanhMucUngDungService.FormData.FileName}}" class=""
                style="display: block; margin-left: auto; margin-right: auto; width: 50%;" />
        </div>
        <div class="col-lg-10 col-sm-12 col-12">
            <br />
            <h3 class="text-center mt-3" style="color: #208e1e;"><b>{{DanhMucUngDungService.FormData.Display}}</b></h3>
            <!-- <h5 class="text-center" style="color: #30897f;"><b>Cổng Truy Xuất Nguồn Gốc Hàng Hóa và Carbon
                Footprint</b></h5> -->
            <a title="Đăng nhập để khai báo thông tin" target="_blank" href="{{DanhMucUngDungService.FormData.Code}}{{LoHangInfoByQRCode}}/{{LoHangService.FormData.ID}}">
                <h5 class="text-center" style="color: #30897f;">Đăng nhập để khai báo thông tin</h5>
            </a>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="badge bg-primary-subtle border border-primary-subtle rounded-pill" style="padding-top: 10px;">
            <h6 class="text-center" style="color: #000000;">Hàng hóa:
                <b>{{LoHangService.FormData.DanhMucSanPhamName}}</b>
            </h6>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="badge bg-success-subtle border border-success-subtle rounded-pill" style="padding-top: 10px;">
            <h6 class="text-center" style="color: #000000;"><b>{{LoHangService.FormData.ParentName}}</b></h6>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-lg-2 col-sm-3 col-3" style="margin-bottom: 10px;"
            *ngFor="let LoHangTapTinDinhKem of LoHangTapTinDinhKemService.List001;">
            <img style="cursor: pointer; max-height: 200px;" (click)="LoHangTapTinDinhKemAdd(LoHangTapTinDinhKem.ID)"
                src="{{LoHangTapTinDinhKem.FileName}}" class="img-thumbnail" />
        </div>
    </div>
    <div class="row">
        <div class="badge bg-info-subtle border border-info-subtle rounded-pill" style="padding-top: 10px;">
            <h5 class="text-center" style="color: #000000;">Mã số truy vết sản phẩm:
                <b>{{TemPhieuService.FormData.MaSo}}</b>
            </h5>
        </div>
    </div>
    <br />
    <div class="row bg-success" style="color: #ffffff; padding: 10px;" *ngIf="IsPheDuyet">
        <!-- <div class="col-lg-2 col-sm-12 col-12">
            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor"
                class="bi bi-clipboard-check" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                    d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0" />
                <path
                    d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                <path
                    d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
            </svg>
        </div> -->
        <div class="col-lg-12 col-sm-12 col-12">
            <div class="col-lg-12 col-sm-12 col-12">
                <h6>Hàng hóa đã được kiểm tra phát hành mã truy xuất nguồn gốc của Cơ Quan Quản Lý</h6>
                <h6>Được kiểm duyệt bởi: <b>{{DanhMucUngDungService.FormData.Display}}</b></h6>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
                <h6>Phụ trách kiểm duyệt: <b>{{LoHangService.FormData.ThanhVienNamePheDuyet}}</b></h6>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
                <h6>Ngày duyệt: <b>{{LoHangService.FormData.PheDuyet |
                        date:'dd/MM/yyyy'}}</b></h6>
            </div>
        </div>
    </div>
    <div class="row bg-warning" style="color: #ffffff; padding: 10px;" *ngIf="IsHetHanSuDung">
        <!-- <div class="col-lg-2 col-sm-12 col-12">
            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor"
                class="bi bi-clipboard-x" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                    d="M6.146 7.146a.5.5 0 0 1 .708 0L8 8.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 9l1.147 1.146a.5.5 0 0 1-.708.708L8 9.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 9 6.146 7.854a.5.5 0 0 1 0-.708" />
                <path
                    d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                <path
                    d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
            </svg>
        </div> -->
        <div class="col-lg-12 col-sm-12 col-12">
            <div class="col-lg-12 col-sm-12 col-12">
                <h6>Hàng hóa hết hạn sử dụng.</h6>
                <h6>Được kiểm duyệt bởi: <b>{{DanhMucUngDungService.FormData.Display}}</b></h6>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
                <h6>Phụ trách kiểm duyệt: <b>{{LoHangService.FormData.ThanhVienNamePheDuyet}}</b></h6>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
                <h6>Ngày duyệt: <b>{{LoHangService.FormData.PheDuyet |
                        date:'dd/MM/yyyy'}}</b></h6>
            </div>
        </div>
    </div>
    <div class="row bg-danger" style="color: #ffffff; padding: 10px;" *ngIf="IsThuHoi">
        <!-- <div class="col-lg-2 col-sm-12 col-12">
            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" class="bi bi-trash"
                viewBox="0 0 16 16">
                <path
                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                <path
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
            </svg>
        </div> -->
        <div class="col-lg-12 col-sm-12 col-12">
            <div class="col-lg-12 col-sm-12 col-12">
                <h6>Hàng hóa bị thu hồi.</h6>
                <h6>Được kiểm duyệt bởi: <b>{{DanhMucUngDungService.FormData.Display}}</b></h6>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
                <h6>Phụ trách kiểm duyệt: <b>{{LoHangService.FormData.ThanhVienNameThuHoi}}</b></h6>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
                <h6>Ngày duyệt: <b>{{LoHangService.FormData.ThuHoi |
                        date:'dd/MM/yyyy'}}</b></h6>
            </div>
        </div>
    </div>
    <div class="row bg-warning" style="color: red; padding: 10px; padding-top: 15px;" *ngIf="IsChuY">
        <!-- <div class="col-lg-2 col-sm-12 col-12">
            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor"
                class="bi bi-clipboard-pulse" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                    d="M10 1.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5zm-5 0A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5v1A1.5 1.5 0 0 1 9.5 4h-3A1.5 1.5 0 0 1 5 2.5zm-2 0h1v1H3a1 1 0 0 0-1 1V14a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3.5a1 1 0 0 0-1-1h-1v-1h1a2 2 0 0 1 2 2V14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3.5a2 2 0 0 1 2-2m6.979 3.856a.5.5 0 0 0-.968.04L7.92 10.49l-.94-3.135a.5.5 0 0 0-.895-.133L4.232 10H3.5a.5.5 0 0 0 0 1h1a.5.5 0 0 0 .416-.223l1.41-2.115 1.195 3.982a.5.5 0 0 0 .968-.04L9.58 7.51l.94 3.135A.5.5 0 0 0 11 11h1.5a.5.5 0 0 0 0-1h-1.128z" />
            </svg>
        </div> -->
        <div class="col-lg-12 col-sm-12 col-12">
            <div class="col-lg-12 col-sm-12 col-12">
                <h6>Hàng hóa đang kiểm tra hoạt động truy xuất nguồn gốc, chưa được phê duyệt bởi Cơ quan quản lý.</h6>
                <h6>Đơn vị kiểm tra: <b>{{DanhMucUngDungService.FormData.Display}}</b></h6>
            </div>
        </div>
    </div>
    <div class="row" style="padding: 10px 0">
        <div class="col-lg-4 col-sm-12 col-12" style="margin-bottom: 5px;">
            <div class="bg-success text-center" style="font-weight: bold; color: #ffffff; padding: 10px;">
                Số lần quét: {{TemPhieuService.FormData.SoLanKiemTra}} (lần)
            </div>
        </div>
        <div class="col-lg-4 col-sm-12 col-12" style="margin-bottom: 5px;">
            <div (click)="ToChucAdd(0)" class="bg-primary text-center"
                style="font-weight: bold; color: #ffffff; padding: 10px; cursor: pointer;">
                Liên hệ trực tiếp đến Nhà Cung Cấp
            </div>
        </div>
        <div class="col-lg-4 col-sm-12 col-12" style="margin-bottom: 5px;">
            <div (click)="LoHangPhanAnhAdd(0)" class="bg-danger text-center"
                style="font-weight: bold; color: #ffffff; padding: 10px; cursor: pointer;">
                Phản ánh về chất lượng Hàng hóa
            </div>
        </div>
    </div>
    <div class="row" style="border-color: #e4e4e4; border-style: solid; border-width: 1px; margin: 0px;">
        <div class="col-lg-12 col-sm-12 col-12 bg-success text-light" style="cursor: pointer;"
            (click)="ChangeIsTongHop()">
            <h5 style="padding: 5px; padding-top: 12px;">
                Thông tin tổng hợp
            </h5>
        </div>
        <div class="row" style="padding-top: 10px;" *ngIf="IsTongHop">
            <div class="col-lg-6 col-sm-12 col-12">
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Hàng hóa:
                        <b
                            *ngIf="LoHangService.FormData.DanhMucSanPhamName">{{LoHangService.FormData.DanhMucSanPhamName}}</b>
                        <span *ngIf="LoHangService.FormData.DanhMucSanPhamName==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Nhà sản xuất:
                        <b
                            *ngIf="LoHangService.FormData.SanPhamNhaCungCap">{{LoHangService.FormData.SanPhamNhaCungCap}}</b>
                        <span *ngIf="LoHangService.FormData.SanPhamNhaCungCap==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Thời gian sản xuất theo nhật ký:
                        <b
                            *ngIf="LoHangNhatKyService.FormData.NgayGhiNhan && LoHangNhatKyService.FormData001.NgayGhiNhan">{{LoHangNhatKyService.FormData.NgayGhiNhan
                            | date:'dd/MM/yyyy'}} - {{LoHangNhatKyService.FormData001.NgayGhiNhan |
                            date:'dd/MM/yyyy'}}</b>
                        <span *ngIf="LoHangNhatKyService.FormData.NgayGhiNhan==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Mã lô đóng gói hàng hóa:
                        <b *ngIf="LoHangService.FormData.MaLoHang">{{LoHangService.FormData.MaLoHang}}</b>
                        <span *ngIf="LoHangService.FormData.MaLoHang==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Số lượng Hàng hóa trong lô hàng:
                        <b *ngIf="LoHangService.FormData.SanPhamSoLuong">{{LoHangService.FormData.SanPhamSoLuong |
                            number:'1.0-0'}}</b>
                        <span *ngIf="LoHangService.FormData.SanPhamSoLuong==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Ngày sản xuất (đóng gói):
                        <b *ngIf="LoHangService.FormData.NgaySanXuatDongGoi">{{LoHangService.FormData.NgaySanXuatDongGoi
                            | date:'dd/MM/yyyy'}}</b>
                        <span *ngIf="LoHangService.FormData.NgaySanXuatDongGoi==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Ngày hết hạn sử dụng:
                        <b *ngIf="LoHangService.FormData.NgayHetHanSuDung">{{LoHangService.FormData.NgayHetHanSuDung |
                            date:'dd/MM/yyyy'}}</b>
                        <span *ngIf="LoHangService.FormData.NgayHetHanSuDung==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Các tiêu chuẩn / chứng nhận / giấy phép đã có:
                        <!-- <b *ngIf="LoHangService.FormData.SanPhamGioiThieu">{{LoHangService.FormData.SanPhamGioiThieu}}</b>
                        <b *ngIf="LoHangService.FormData.SanPhamGioiThieu==null" style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</b> -->
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12"
                    *ngFor="let LoHangTapTinDinhKem of LoHangTapTinDinhKemService.List002;">
                    <a style="cursor: pointer;" (click)="LoHangTapTinDinhKemAdd(LoHangTapTinDinhKem.ID)"
                        title="{{LoHangTapTinDinhKem.Name}}">{{LoHangTapTinDinhKem.Name}}</a>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <span *ngIf="LoHangTapTinDinhKemService.List002==null"
                        style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Mã số vùng trồng:
                        <b
                            *ngIf="LoHangService.FormData.VungNguyenLieuMaSoVungTrong">{{LoHangService.FormData.VungNguyenLieuMaSoVungTrong}}</b>
                        <span *ngIf="LoHangService.FormData.VungNguyenLieuMaSoVungTrong==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Mã số cơ sở đóng gói:
                        <b *ngIf="LoHangService.FormData.CoSoDongGoiMaSo">{{LoHangService.FormData.CoSoDongGoiMaSo}}</b>
                        <span *ngIf="LoHangService.FormData.CoSoDongGoiMaSo==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Mã số chỉ dẫn địa lý:
                        <b *ngIf="LoHangService.FormData.MaSoChiDanDiaLy">{{LoHangService.FormData.MaSoChiDanDiaLy}}</b>
                        <span *ngIf="LoHangService.FormData.MaSoChiDanDiaLy==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Mã HS Code:
                        <b *ngIf="LoHangService.FormData.HSCode">{{LoHangService.FormData.HSCode}}</b>
                        <span *ngIf="LoHangService.FormData.HSCode==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Mã GS1:
                        <b *ngIf="LoHangService.FormData.GS1">{{LoHangService.FormData.GS1}}</b>
                        <span *ngIf="LoHangService.FormData.GS1==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Mã GTIN:
                        <b
                            *ngIf="LoHangService.FormData.VanChuyenGTIN">{{LoHangNhatKyService.FormData.VanChuyenGTIN}}</b>
                        <span *ngIf="LoHangService.FormData.VanChuyenGTIN==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Đơn vị đăng ký phát hành mã truy xuất nguồn gốc:</p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p> + Đơn vị:
                        <b *ngIf="ToChucService.FormData.Name">{{ToChucService.FormData.Name}}</b>
                        <span *ngIf="ToChucService.FormData.Name==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p> + Mã số đăng ký kinh doanh:
                        <b *ngIf="ToChucService.FormData.MaSoThue">{{ToChucService.FormData.MaSoThue}}</b>
                        <span *ngIf="ToChucService.FormData.MaSoThue==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p> + Địa chỉ:
                        <b *ngIf="ToChucService.FormData.DiaChi">{{ToChucService.FormData.DiaChi}}</b>
                        <span *ngIf="ToChucService.FormData.DiaChi==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p> + Điện thoại chăm sóc khách hàng:
                        <b
                            *ngIf="LoHangService.FormData.SanPhamDienThoai">{{LoHangService.FormData.SanPhamDienThoai}}</b>
                        <span *ngIf="LoHangService.FormData.SanPhamDienThoai==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Số lượng mã truy xuất nguồn gốc đăng ký phát hành theo lô:
                        <b *ngIf="LoHangService.FormData.SanPhamSoLuongMa">{{LoHangService.FormData.SanPhamSoLuongMa |
                            number:'1.0-0'}}</b>
                        <span *ngIf="LoHangService.FormData.SanPhamSoLuongMa==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Ngày đăng ký phát hành mã truy xuất nguồn gốc:
                        <b *ngIf="LoHangService.FormData.CongBo">{{LoHangService.FormData.CongBo |
                            date:'dd/MM/yyyy'}}</b>
                        <span *ngIf="LoHangService.FormData.CongBo==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Ngày Cơ quan quản lý phê duyệt phát hành mã truy xuất nguồn gốc:
                        <b *ngIf="LoHangService.FormData.PheDuyet">{{LoHangService.FormData.PheDuyet |
                            date:'dd/MM/yyyy'}}</b>
                        <span *ngIf="LoHangService.FormData.PheDuyet==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Loại hình truy xuất nguồn gốc:
                        <b
                            *ngIf="LoHangService.FormData.DanhMucLoaiHinhName">{{LoHangService.FormData.DanhMucLoaiHinhName}}</b>
                        <span *ngIf="LoHangService.FormData.DanhMucLoaiHinhName==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Phương thức phát hành tem truy xuất nguồn gốc:
                        <b
                            *ngIf="LoHangService.FormData.DanhMucPhuongThucSanXuatName">{{LoHangService.FormData.DanhMucPhuongThucSanXuatName}}</b>
                        <span *ngIf="LoHangService.FormData.DanhMucPhuongThucSanXuatName==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="border-color: #e4e4e4; border-style: solid; border-width: 1px; margin: 0px;">
        <div class="col-lg-12 col-sm-12 col-12 bg-info text-light" style="cursor: pointer;" (click)="ChangeIsLoHang()">
            <h5 style="padding: 5px; padding-top: 12px;">
                Lô hàng hóa
            </h5>
        </div>
        <div class="row" style="padding-top: 10px;" *ngIf="IsLoHang">
            <div class="col-lg-6 col-sm-12 col-12">
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Lô hàng:
                        <b *ngIf="LoHangService.FormData.Name">{{LoHangService.FormData.Name}}</b>
                        <span *ngIf="LoHangService.FormData.Name==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Mã lô đóng gói hàng hóa:
                        <b *ngIf="LoHangService.FormData.MaLoHang">{{LoHangService.FormData.MaLoHang}}</b>
                        <span *ngIf="LoHangService.FormData.MaLoHang==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Số lượng Hàng hóa của lô hàng:
                        <b *ngIf="LoHangService.FormData.SanPhamSoLuong">{{LoHangService.FormData.SanPhamSoLuong |
                            number:'1.0-0'}}</b>
                        <span *ngIf="LoHangService.FormData.SanPhamSoLuong==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Số lượng mã truy xuất nguồn gốc đăng ký phát hành theo lô:
                        <b *ngIf="LoHangService.FormData.SanPhamSoLuongMa">{{LoHangService.FormData.SanPhamSoLuongMa |
                            number:'1.0-0'}}</b>
                        <span *ngIf="LoHangService.FormData.SanPhamSoLuongMa==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="border-color: #e4e4e4; border-style: solid; border-width: 1px; margin: 0px;">
        <div class="col-lg-12 col-sm-12 col-12 bg-info text-light" style="cursor: pointer;" (click)="ChangeIsSanPham()">
            <h5 style="padding: 5px; padding-top: 12px;">
                Hàng hóa
            </h5>
        </div>
        <div class="row" style="padding-top: 10px;" *ngIf="IsSanPham">
            <div class="col-lg-6 col-sm-12 col-12">
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Hàng hóa:
                        <b *ngIf="LoHangService.FormData.Name">{{LoHangService.FormData.Name}}</b>
                        <span *ngIf="LoHangService.FormData.Name==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Mã GS1:
                        <b *ngIf="LoHangService.FormData.MaLoHang">{{LoHangService.FormData.MaLoHang}}</b>
                        <span *ngIf="LoHangService.FormData.MaLoHang==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Mã HSCode:
                        <b *ngIf="LoHangService.FormData.HSCode">{{LoHangService.FormData.HSCode}}</b>
                        <span *ngIf="LoHangService.FormData.HSCode==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Mã số chỉ dẫn địa lý:
                        <b *ngIf="LoHangService.FormData.MaSoChiDanDiaLy">{{LoHangService.FormData.MaSoChiDanDiaLy}}</b>
                        <span *ngIf="LoHangService.FormData.MaSoChiDanDiaLy==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Ngày sản xuất/ đóng gói:
                        <b *ngIf="LoHangService.FormData.NgaySanXuatDongGoi">{{LoHangService.FormData.NgaySanXuatDongGoi
                            | date:'dd/MM/yyyy'}}</b>
                        <span *ngIf="LoHangService.FormData.NgaySanXuatDongGoi==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Ngày hết hạn sử dụng:
                        <b *ngIf="LoHangService.FormData.NgayHetHanSuDung">{{LoHangService.FormData.NgayHetHanSuDung |
                            date:'dd/MM/yyyy'}}</b>
                        <span *ngIf="LoHangService.FormData.NgayHetHanSuDung==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>HSD sau mở hộp PAO (tháng):
                        <b *ngIf="LoHangService.FormData.HanSuDungPAO">{{LoHangService.FormData.HanSuDungPAO}}</b>
                        <span *ngIf="LoHangService.FormData.HanSuDungPAO==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Khối lượng Hàng hóa (gram):
                        <b
                            *ngIf="LoHangService.FormData.SanPhamKhoiLuong">{{LoHangService.FormData.SanPhamKhoiLuong}}</b>
                        <span *ngIf="LoHangService.FormData.SanPhamKhoiLuong==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Quy cách đóng gói (Theo bao bì):
                        <b *ngIf="LoHangService.FormData.SanPhamDongGoi">{{LoHangService.FormData.SanPhamDongGoi}}</b>
                        <span *ngIf="LoHangService.FormData.SanPhamDongGoi==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Quy cách bảo quản:
                        <b *ngIf="LoHangService.FormData.SanPhamBaoQuan">{{LoHangService.FormData.SanPhamBaoQuan}}</b>
                        <span *ngIf="LoHangService.FormData.SanPhamBaoQuan==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Loại hình Hàng hóa:
                        <b *ngIf="LoHangService.FormData.SanPhamLoaiHinh">{{LoHangService.FormData.SanPhamLoaiHinh}}</b>
                        <span *ngIf="LoHangService.FormData.SanPhamLoaiHinh==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Giá thấp nhất (đồng):
                        <b *ngIf="LoHangService.FormData.GiaThapNhat">{{LoHangService.FormData.GiaThapNhat |
                            number:'1.0-0'}}</b>
                        <span *ngIf="LoHangService.FormData.GiaThapNhat==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Giá cao nhất (đồng):
                        <b *ngIf="LoHangService.FormData.GiaCaoNhat">{{LoHangService.FormData.GiaCaoNhat |
                            number:'1.0-0'}}</b>
                        <span *ngIf="LoHangService.FormData.GiaCaoNhat==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Ngày công bố:
                        <b *ngIf="LoHangService.FormData.CongBo">{{LoHangService.FormData.CongBo |
                            date:'dd/MM/yyyy'}}</b>
                        <span *ngIf="LoHangService.FormData.CongBo==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Xuất xứ:
                        <b
                            *ngIf="LoHangService.FormData.DanhMucQuocGiaName">{{LoHangService.FormData.DanhMucQuocGiaName}}</b>
                        <span *ngIf="LoHangService.FormData.DanhMucQuocGiaName==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Nhóm Hàng hóa:
                        <b
                            *ngIf="LoHangService.FormData.DanhMucNhomSanPhamName">{{LoHangService.FormData.DanhMucNhomSanPhamName}}</b>
                        <span *ngIf="LoHangService.FormData.DanhMucNhomSanPhamName==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Nhóm ngành nghề:
                        <b
                            *ngIf="LoHangService.FormData.DanhMucNganhNgheName">{{LoHangService.FormData.DanhMucNganhNgheName}}</b>
                        <span *ngIf="LoHangService.FormData.DanhMucNganhNgheName==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Đơn vị tính:
                        <b
                            *ngIf="LoHangService.FormData.DanhMucDonViTinhName">{{LoHangService.FormData.DanhMucDonViTinhName}}</b>
                        <span *ngIf="LoHangService.FormData.DanhMucDonViTinhName==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Thị trường phân phối:
                        <b
                            *ngIf="LoHangService.FormData.SanPhamThiTruong">{{LoHangService.FormData.SanPhamThiTruong}}</b>
                        <span *ngIf="LoHangService.FormData.SanPhamThiTruong==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Thông tin khuyến cáo người dùng:
                        <b *ngIf="LoHangService.FormData.SanPhamCanhBao">{{LoHangService.FormData.SanPhamCanhBao}}</b>
                        <span *ngIf="LoHangService.FormData.SanPhamCanhBao==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Mô tả thành phần Hàng hóa (chi tiết theo hồ sơ công bố):
                        <b
                            *ngIf="LoHangService.FormData.SanPhamThanhPhan">{{LoHangService.FormData.SanPhamThanhPhan}}</b>
                        <span *ngIf="LoHangService.FormData.SanPhamThanhPhan==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Giới thiệu ngắn về Hàng hóa:
                        <b
                            *ngIf="LoHangService.FormData.SanPhamGioiThieu">{{LoHangService.FormData.SanPhamGioiThieu}}</b>
                        <span *ngIf="LoHangService.FormData.SanPhamGioiThieu==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Giới thiệu ngắn về nhà cung cấp:
                        <b
                            *ngIf="LoHangService.FormData.SanPhamNhaCungCap">{{LoHangService.FormData.SanPhamNhaCungCap}}</b>
                        <span *ngIf="LoHangService.FormData.SanPhamNhaCungCap==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Đường dẫn youtube về Hàng hóa:
                        <b *ngIf="LoHangService.FormData.YouTube">{{LoHangService.FormData.YouTube}}</b>
                        <span *ngIf="LoHangService.FormData.YouTube==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Điện thoại chăm sóc khách hàng:
                        <b
                            *ngIf="LoHangService.FormData.SanPhamDienThoai">{{LoHangService.FormData.SanPhamDienThoai}}</b>
                        <span *ngIf="LoHangService.FormData.SanPhamDienThoai==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="border-color: #e4e4e4; border-style: solid; border-width: 1px; margin: 0px;">
        <div class="col-lg-12 col-sm-12 col-12 bg-info text-light" style="cursor: pointer;"
            (click)="ChangeIsNhaCungCap()">
            <h5 style="padding: 5px; padding-top: 12px;">
                Nhà cung cấp
            </h5>
        </div>
        <div class="row" style="padding-top: 10px;" *ngIf="IsNhaCungCap">
            <div class="col-lg-6 col-sm-12 col-12">
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Đơn vị:
                        <b *ngIf="ToChucService.FormData.Name">{{ToChucService.FormData.Name}}</b>
                        <span *ngIf="ToChucService.FormData.Name==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Đăng ký kinh doanh:
                        <b *ngIf="ToChucService.FormData.MaSoThue">{{ToChucService.FormData.MaSoThue}}</b>
                        <span *ngIf="ToChucService.FormData.MaSoThue==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Điện thoại:
                        <b *ngIf="ToChucService.FormData.DienThoai">{{ToChucService.FormData.DienThoai}}</b>
                        <span *ngIf="ToChucService.FormData.DienThoai==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Địa chỉ:
                        <b *ngIf="ToChucService.FormData.DiaChi">{{ToChucService.FormData.DiaChi}}</b>
                        <span *ngIf="ToChucService.FormData.DiaChi==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Đại điện pháp luật:
                        <b *ngIf="ToChucService.FormData.LienHeHoTen">{{ToChucService.FormData.LienHeHoTen}}</b>
                        <span *ngIf="ToChucService.FormData.LienHeHoTen==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Chức danh:
                        <b *ngIf="ToChucService.FormData.LienHeChucDanh">{{ToChucService.FormData.LienHeChucDanh}}</b>
                        <span *ngIf="ToChucService.FormData.LienHeChucDanh==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Điện thoại:
                        <b *ngIf="ToChucService.FormData.LienHeChucDanh">{{ToChucService.FormData.LienHeDienThoai}}</b>
                        <span *ngIf="ToChucService.FormData.LienHeChucDanh==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Email:
                        <b *ngIf="ToChucService.FormData.LienHeEmail">{{ToChucService.FormData.LienHeEmail}}</b>
                        <span *ngIf="ToChucService.FormData.LienHeEmail==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="border-color: #e4e4e4; border-style: solid; border-width: 1px; margin: 0px;">
        <div class="col-lg-12 col-sm-12 col-12 bg-info text-light" style="cursor: pointer;"
            (click)="ChangeIsVungNguyenLieu()">
            <h5 style="padding: 5px; padding-top: 12px;">
                Vùng nguyên liệu
            </h5>
        </div>
        <div class="row" style="padding-top: 10px;" *ngIf="IsVungNguyenLieu">
            <div class="row" *ngIf="LoHangService.FormData.DanhMucUngDungID!=5">
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <p>Chuẩn loại (Giống):
                            <b
                                *ngIf="ToChucService.FormData.VungNguyenLieuGiong">{{LoHangService.FormData.VungNguyenLieuGiong}}</b>
                            <span *ngIf="ToChucService.FormData.VungNguyenLieuGiong==null"
                                style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                        </p>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <p>Tên khoa học:
                            <b
                                *ngIf="ToChucService.FormData.VungNguyenLieuKhoaHoc">{{LoHangService.FormData.VungNguyenLieuKhoaHoc}}</b>
                            <span *ngIf="ToChucService.FormData.VungNguyenLieuKhoaHoc==null"
                                style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                        </p>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <p>Số vụ trong năm:
                            <b
                                *ngIf="ToChucService.FormData.VungNguyenLieuSoVu">{{LoHangService.FormData.VungNguyenLieuSoVu}}</b>
                            <span *ngIf="ToChucService.FormData.VungNguyenLieuSoVu==null"
                                style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                        </p>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <p>Sản lượng trung bình/vụ:
                            <b *ngIf="ToChucService.FormData.VungNguyenLieuSanLuongTrungBinh">{{LoHangService.FormData.VungNguyenLieuSanLuongTrungBinh
                                | number:'1.0-0'}}</b>
                            <span *ngIf="ToChucService.FormData.VungNguyenLieuSanLuongTrungBinh==null"
                                style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                        </p>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <p>Danh sách mã vùng trồng:
                            <b
                                *ngIf="ToChucService.FormData.VungNguyenLieuMaSoVungTrong">{{LoHangService.FormData.VungNguyenLieuMaSoVungTrong}}</b>
                            <span *ngIf="ToChucService.FormData.VungNguyenLieuMaSoVungTrong==null"
                                style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                        </p>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <p>Tổng diện tích vùng nguyên liệu:
                            <b *ngIf="ToChucService.FormData.VungNguyenLieuMaSoVungTrong">{{LoHangService.FormData.VungNguyenLieuDienTich
                                | number:'1.0-0'}}</b>
                            <span *ngIf="ToChucService.FormData.VungNguyenLieuMaSoVungTrong==null"
                                style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                        </p>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <p>Tiêu chuẩn, chứng nhận của vùng nguyên liệu:
                            <b
                                *ngIf="ToChucService.FormData.VungNguyenLieuTieuChuan">{{LoHangService.FormData.VungNguyenLieuTieuChuan}}</b>
                            <span *ngIf="ToChucService.FormData.VungNguyenLieuTieuChuan==null"
                                style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                        </p>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <p>Ghi chú:
                            <b
                                *ngIf="ToChucService.FormData.VungNguyenLieuGhiChu">{{LoHangService.FormData.VungNguyenLieuGhiChu}}</b>
                            <span *ngIf="ToChucService.FormData.VungNguyenLieuGhiChu==null"
                                style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="LoHangService.FormData.DanhMucUngDungID!=5">
                OK
            </div>
        </div>
    </div>
    <div class="row" style="border-color: #e4e4e4; border-style: solid; border-width: 1px; margin: 0px;">
        <div class="col-lg-12 col-sm-12 col-12 bg-info text-light" style="cursor: pointer;" (click)="ChangeIsNhaMay()">
            <h5 style="padding: 5px; padding-top: 12px;">
                Nhà máy sản xuất/chế biến
            </h5>
        </div>
        <div class="row" style="padding-top: 10px;" *ngIf="IsNhaMay">
            <div class="col-lg-6 col-sm-12 col-12">
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Tiêu đề:
                        <b
                            *ngIf="ToChucService.FormData.NhaMayCheBienName">{{LoHangService.FormData.NhaMayCheBienName}}</b>
                        <span *ngIf="ToChucService.FormData.NhaMayCheBienName==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Mã số:
                        <b
                            *ngIf="ToChucService.FormData.NhaMayCheBienMaSo">{{LoHangService.FormData.NhaMayCheBienMaSo}}</b>
                        <span *ngIf="ToChucService.FormData.NhaMayCheBienMaSo==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Tiêu chuẩn, chứng nhận của nhà máy:
                        <b
                            *ngIf="ToChucService.FormData.NhaMayCheBienTieuChuan">{{LoHangService.FormData.NhaMayCheBienTieuChuan}}</b>
                        <span *ngIf="ToChucService.FormData.NhaMayCheBienTieuChuan==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Địa chỉ:
                        <b
                            *ngIf="ToChucService.FormData.NhaMayCheBienDiaChi">{{LoHangService.FormData.NhaMayCheBienDiaChi}}</b>
                        <span *ngIf="ToChucService.FormData.NhaMayCheBienDiaChi==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Kinh độ:
                        <b
                            *ngIf="ToChucService.FormData.NhaMayCheBienKinhDo">{{LoHangService.FormData.NhaMayCheBienKinhDo}}</b>
                        <span *ngIf="ToChucService.FormData.NhaMayCheBienKinhDo==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Vĩ độ:
                        <b
                            *ngIf="ToChucService.FormData.NhaMayCheBienViDo">{{LoHangService.FormData.NhaMayCheBienViDo}}</b>
                        <span *ngIf="ToChucService.FormData.NhaMayCheBienViDo==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="border-color: #e4e4e4; border-style: solid; border-width: 1px; margin: 0px;">
        <div class="col-lg-12 col-sm-12 col-12 bg-info text-light" style="cursor: pointer;"
            (click)="ChangeIsCoSoDongGoi()">
            <h5 style="padding: 5px; padding-top: 12px;">
                Cơ sở đóng gói
            </h5>
        </div>
        <div class="row" style="padding-top: 10px;" *ngIf="IsCoSoDongGoi">
            <div class="col-lg-6 col-sm-12 col-12">
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Tiêu đề:
                        <b *ngIf="ToChucService.FormData.CoSoDongGoiName">{{LoHangService.FormData.CoSoDongGoiName}}</b>
                        <span *ngIf="ToChucService.FormData.CoSoDongGoiName==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Mã số:
                        <b *ngIf="ToChucService.FormData.CoSoDongGoiMaSo">{{LoHangService.FormData.CoSoDongGoiMaSo}}</b>
                        <span *ngIf="ToChucService.FormData.CoSoDongGoiMaSo==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Ghi chú:
                        <b
                            *ngIf="ToChucService.FormData.CoSoDongGoiGhiChu">{{LoHangService.FormData.CoSoDongGoiGhiChu}}</b>
                        <span *ngIf="ToChucService.FormData.CoSoDongGoiGhiChu==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Địa chỉ:
                        <b
                            *ngIf="ToChucService.FormData.CoSoDongGoiDiaChi">{{LoHangService.FormData.CoSoDongGoiDiaChi}}</b>
                        <span *ngIf="ToChucService.FormData.CoSoDongGoiDiaChi==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Kinh độ:
                        <b
                            *ngIf="ToChucService.FormData.CoSoDongGoiKinhDo">{{LoHangService.FormData.CoSoDongGoiKinhDo}}</b>
                        <span *ngIf="ToChucService.FormData.CoSoDongGoiKinhDo==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Vĩ độ:
                        <b *ngIf="ToChucService.FormData.CoSoDongGoiViDo">{{LoHangService.FormData.CoSoDongGoiViDo}}</b>
                        <span *ngIf="ToChucService.FormData.CoSoDongGoiViDo==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="border-color: #e4e4e4; border-style: solid; border-width: 1px; margin: 0px;">
        <div class="col-lg-12 col-sm-12 col-12 bg-info text-light" style="cursor: pointer;"
            (click)="ChangeIsKhoLuuTru()">
            <h5 style="padding: 5px; padding-top: 12px;">
                Kho lưu trữ
            </h5>
        </div>
        <div class="row" style="padding-top: 10px;" *ngIf="IsKhoLuuTru">
            <div class="col-lg-6 col-sm-12 col-12">
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Tiêu đề:
                        <b *ngIf="ToChucService.FormData.KhoLuuTruName">{{LoHangService.FormData.KhoLuuTruName}}</b>
                        <span *ngIf="ToChucService.FormData.KhoLuuTruName==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Mã kho:
                        <b *ngIf="ToChucService.FormData.KhoLuuTruMaSo">{{LoHangService.FormData.KhoLuuTruMaSo}}</b>
                        <span *ngIf="ToChucService.FormData.KhoLuuTruMaSo==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Điều kiện lưu trữ:
                        <b
                            *ngIf="ToChucService.FormData.KhoLuuTruDieuKien">{{LoHangService.FormData.KhoLuuTruDieuKien}}</b>
                        <span *ngIf="ToChucService.FormData.KhoLuuTruDieuKien==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Nhiệt độ lưu trữ:
                        <b
                            *ngIf="ToChucService.FormData.KhoLuuTruNhietDo">{{LoHangService.FormData.KhoLuuTruNhietDo}}</b>
                        <span *ngIf="ToChucService.FormData.KhoLuuTruNhietDo==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Địa chỉ:
                        <b *ngIf="ToChucService.FormData.KhoLuuTruDiaChi">{{LoHangService.FormData.KhoLuuTruDiaChi}}</b>
                        <span *ngIf="ToChucService.FormData.KhoLuuTruDiaChi==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Kinh độ:
                        <b *ngIf="ToChucService.FormData.KhoLuuTruKinhDo">{{LoHangService.FormData.KhoLuuTruKinhDo}}</b>
                        <span *ngIf="ToChucService.FormData.KhoLuuTruKinhDo==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Vĩ độ:
                        <b *ngIf="ToChucService.FormData.KhoLuuTruViDo">{{LoHangService.FormData.KhoLuuTruViDo}}</b>
                        <span *ngIf="ToChucService.FormData.KhoLuuTruViDo==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Ghi chú:
                        <b *ngIf="ToChucService.FormData.KhoLuuTruGhiChu">{{LoHangService.FormData.KhoLuuTruGhiChu}}</b>
                        <span *ngIf="ToChucService.FormData.KhoLuuTruGhiChu==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="border-color: #e4e4e4; border-style: solid; border-width: 1px; margin: 0px;">
        <div class="col-lg-12 col-sm-12 col-12 bg-info text-light" style="cursor: pointer;"
            (click)="ChangeIsKhoThuongMai()">
            <h5 style="padding: 5px; padding-top: 12px;">
                Kho thương mại
            </h5>
        </div>
        <div class="row" style="padding-top: 10px;" *ngIf="IsKhoThuongMai">
            <div class="col-lg-6 col-sm-12 col-12">
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Tiêu đề:
                        <b
                            *ngIf="ToChucService.FormData.KhoThuongMaiName">{{LoHangService.FormData.KhoThuongMaiName}}</b>
                        <span *ngIf="ToChucService.FormData.KhoThuongMaiName==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Mã kho:
                        <b
                            *ngIf="ToChucService.FormData.KhoThuongMaiMaSo">{{LoHangService.FormData.KhoThuongMaiMaSo}}</b>
                        <span *ngIf="ToChucService.FormData.KhoThuongMaiMaSo==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Điều kiện lưu trữ:
                        <b
                            *ngIf="ToChucService.FormData.KhoThuongMaiDieuKien">{{LoHangService.FormData.KhoThuongMaiDieuKien}}</b>
                        <span *ngIf="ToChucService.FormData.KhoThuongMaiDieuKien==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Nhiệt độ lưu trữ:
                        <b
                            *ngIf="ToChucService.FormData.KhoThuongMaiNhietDo">{{LoHangService.FormData.KhoThuongMaiNhietDo}}</b>
                        <span *ngIf="ToChucService.FormData.KhoThuongMaiNhietDo==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Địa chỉ:
                        <b
                            *ngIf="ToChucService.FormData.KhoThuongMaiDiaChi">{{LoHangService.FormData.KhoThuongMaiDiaChi}}</b>
                        <span *ngIf="ToChucService.FormData.KhoThuongMaiDiaChi==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Kinh độ:
                        <b
                            *ngIf="ToChucService.FormData.KhoThuongMaiKinhDo">{{LoHangService.FormData.KhoThuongMaiKinhDo}}</b>
                        <span *ngIf="ToChucService.FormData.KhoThuongMaiKinhDo==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Vĩ độ:
                        <b
                            *ngIf="ToChucService.FormData.KhoThuongMaiViDo">{{LoHangService.FormData.KhoThuongMaiViDo}}</b>
                        <span *ngIf="ToChucService.FormData.KhoThuongMaiViDo==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <p>Ghi chú:
                        <b
                            *ngIf="ToChucService.FormData.KhoThuongMaiGhiChu">{{LoHangService.FormData.KhoThuongMaiGhiChu}}</b>
                        <span *ngIf="ToChucService.FormData.KhoThuongMaiGhiChu==null"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="border-color: #e4e4e4; border-style: solid; border-width: 1px; margin: 0px;">
        <div class="col-lg-12 col-sm-12 col-12 bg-info text-light" style="cursor: pointer;"
            (click)="ChangeIsLoHangMuaBan()">
            <h5 style="padding: 5px; padding-top: 12px;">
                Lịch sử mua bán trao đổi
            </h5>
        </div>
        <div *ngIf="IsLoHangMuaBan">
            <div class="row" style="padding-top: 10px;" *ngFor="let LoHangMuaBan of LoHangMuaBanService.List;">
                <div class="col-lg-4 col-sm-12 col-12">
                    <p>Ghi nhận: <b>{{LoHangMuaBan.NgayGhiNhan |
                            date:'dd/MM/yyyy'}}</b></p>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <p>Phương thức: <b>{{LoHangMuaBan.DanhMucPhuongThucMuaBanName}}</b></p>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <p>Đơn giá: <b>{{LoHangMuaBan.DonGia}}</b></p>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <p>Đơn hàng: <b>{{LoHangMuaBan.Display}}</b></p>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <p>Bên mua: <b>{{LoHangMuaBan.Name}}</b></p>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <p>Điện thoại: <b>{{LoHangMuaBan.DienThoai}}</b></p>
                </div>
                <hr />
            </div>
            <div class="row" style="padding-top: 10px;" *ngIf="LoHangMuaBanService.List.length==0">
                <span *ngIf="LoHangMuaBanService.List.length==0"
                    style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
            </div>
        </div>
    </div>
    <div class="row" style="border-color: #e4e4e4; border-style: solid; border-width: 1px; margin: 0px;">
        <div class="col-lg-12 col-sm-12 col-12 bg-info text-light" style="cursor: pointer;" (click)="ChangeIsNhatKy()">
            <h5 style="padding: 5px; padding-top: 12px;">
                Nhật ký Hàng hóa
            </h5>
        </div>
        <div *ngIf="IsNhatKy">
            <div class="row" style="padding-top: 10px;" *ngFor="let LoHangNhatKy of LoHangNhatKyService.List;">
                <h5 style="cursor: pointer;" (click)="LoHangNhatKyAdd(LoHangNhatKy)">
                    <b>{{LoHangNhatKy.Name}} - {{LoHangNhatKy.NgayGhiNhan |
                        date:'dd/MM/yyyy'}}</b>
                </h5>
                <div class="row" *ngIf="LoHangNhatKy.Active">
                    <div class="col-lg-6 col-sm-12 col-12">
                        <div class="col-lg-12 col-sm-12 col-12">
                            <p>Ghi nhận: <b>{{LoHangNhatKy.NgayGhiNhan |
                                    date:'dd/MM/yyyy'}}</b></p>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12">
                            <p>Quy trình: <b>{{LoHangNhatKy.DanhMucQuyTrinhName}}</b></p>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12">
                            <p>Nhật ký: <b>{{LoHangNhatKy.Name}}</b></p>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12">
                            <p>Người phụ trách: <b>{{LoHangNhatKy.NguoiPhuTrach}}</b></p>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12">
                            <p>Cơ sở đóng gói: <b>{{LoHangNhatKy.CoSoDongGoiName}}</b></p>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12">
                            <p>Mã số Cơ sở đóng gói: <b>{{LoHangNhatKy.CoSoDongGoiMaSo}}</b></p>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12">
                            <p>Vùng trồng: <b>{{LoHangNhatKy.VungTrongName}}</b></p>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12">
                            <p>Mã số Vùng trồng: <b>{{LoHangNhatKy.VungTrongMaSo}}</b></p>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12">
                            <p>Vật tư: <b>{{LoHangNhatKy.VatTuName}}</b></p>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12">
                            <p>Đơn vị tính: <b>{{LoHangNhatKy.VatTuDonViTinh}}</b></p>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12">
                            <p>Số lượng/liều lượng sử dụng: <b>{{LoHangNhatKy.VatTuSoLuong |
                                    number:'1.0-0'}}</b></p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12 col-12">
                        <div class="col-lg-12 col-sm-12 col-12">
                            <p>Đơn vị vận chuyển: <b>{{LoHangNhatKy.VanChuyenName}}</b></p>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12">
                            <p>Phương thức vận chuyển: <b>{{LoHangNhatKy.VanChuyenPhuongThuc}}</b></p>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12">
                            <p>Loại hình vận chuyển: <b>{{LoHangNhatKy.VanChuyenLoaiHinh}}</b></p>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12">
                            <p>Biển số xe: <b>{{LoHangNhatKy.VanChuyenSoHieu}}</b></p>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12">
                            <p>Điểm khởi hành: <b>{{LoHangNhatKy.VanChuyenKhoiHanh}}</b></p>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12">
                            <p>Điểm đến: <b>{{LoHangNhatKy.VanChuyenDiemDen}}</b></p>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12">
                            <p>Mã vận đơn: <b>{{LoHangNhatKy.VanChuyenMaVanDon}}</b></p>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12">
                            <p>Mã GTIN: <b>{{LoHangNhatKy.VanChuyenGTIN}}</b></p>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12">
                            <p>Mô tả ngắn hoạt động: <b>{{LoHangNhatKy.Note}}</b></p>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
            <div class="row" style="padding-top: 10px;" *ngIf="LoHangNhatKyService.List.length==0">
                <span *ngIf="LoHangNhatKyService.List.length==0"
                    style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
            </div>
        </div>
    </div>
    <div class="row" style="border-color: #e4e4e4; border-style: solid; border-width: 1px; margin: 0px;">
        <div class="col-lg-12 col-sm-12 col-12 bg-info text-light" style="cursor: pointer;" (click)="ChangeIsTapTin()">
            <h5 style="padding: 5px; padding-top: 12px;">
                Tập tin đính kèm
            </h5>
        </div>
        <div class="row" style="padding-top: 10px;" *ngIf="IsTapTin">
            <div class="col-lg-12 col-sm-12 col-12"
                *ngFor="let DanhMucTapTinDinhKemPhanLoai of DanhMucTapTinDinhKemPhanLoaiService.List;">
                <h5 style="cursor: pointer;" (click)="DanhMucTapTinDinhKemPhanLoaiAdd(DanhMucTapTinDinhKemPhanLoai)">
                    <b>{{DanhMucTapTinDinhKemPhanLoai.Name}}</b>
                </h5>
                <div class="row" *ngIf="DanhMucTapTinDinhKemPhanLoai.Active">
                    <div class="col-lg-6 col-sm-6 col-6"
                        *ngFor="let LoHangTapTinDinhKem of LoHangTapTinDinhKemService.ListFilter;">
                        <img style="cursor: pointer;" (click)="LoHangTapTinDinhKemAdd(LoHangTapTinDinhKem.ID)"
                            class="img-thumbnail" src="{{LoHangTapTinDinhKem.FileName}}" />
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12" *ngIf="LoHangTapTinDinhKemService.ListFilter.length==0">
                        <span *ngIf="LoHangTapTinDinhKemService.ListFilter.length==0"
                            style="color:darkgray;">{{NhaCungCapChuaCapNhat}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="border-color: #e4e4e4; border-style: solid; border-width: 1px; margin: 0px;">
        <div class="col-lg-12 col-sm-12 col-12 bg-info text-light" style="cursor: pointer;" (click)="ChangeIsMap()">
            <h5 style="padding: 5px; padding-top: 12px;">
                Xem bản đồ số
            </h5>
        </div>
        <div class="row" style="padding-top: 10px;" *ngIf="IsMap">
            <div class="col-lg-12 col-sm-12 col-12">
                <a style="cursor: pointer; color: chocolate;" title="Xem bản đồ số" (click)="MapLoad(0)">
                    <b>Xem bản đồ số</b>
                </a>
                |
                <a style="cursor: pointer;" title="Vùng nguyên liệu" (click)="MapLoad(4)">
                    Vùng nguyên liệu
                </a>
                |
                <a style="cursor: pointer;" title="Tuyến đường vận chuyển" (click)="MapLoad(1)">
                    Tuyến đường vận chuyển
                </a>
                |
                <a style="cursor: pointer;" title="Nhà máy sản xuất/Chế bến và Kho" (click)="MapLoad(2)">
                    Nhà máy sản xuất/Chế bến và Kho
                </a>
                |
                <a style="cursor: pointer;" title="Địa điểm bán hàng" (click)="MapLoad(3)">
                    Địa điểm bán hàng
                </a>
            </div>
            <br />
            <br />
            <div class="col-lg-12 col-sm-12 col-12">
                <div class="map" #map style="height: 600px; width: 100%;">
                    <div style="z-index: 1000; position: absolute; top: 0;">
                        <img src="assets/image/vungtrong.png" />
                    </div>
                    <div
                        style="z-index: 1000; position: absolute; bottom: 0; width: 100%; height: 20px; background-color: #ffffff;">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-lg-12 col-sm-12 col-12">
            <h5 class="text-center"><b>Bình chọn Hàng hóa</b></h5>
        </div>
        <div class="col-lg-2 col-sm-4 col-4">
            <img (click)="LoHangBinhChonAdd(20)" class="img-thumbnail" src="assets/image/sen20.png" title="20%"
                [style]="CSSLoHangBinhChon20" />
            <h5 class="text-center"><b>20%</b></h5>
        </div>
        <div class="col-lg-2 col-sm-4 col-4">
            <img (click)="LoHangBinhChonAdd(40)" class="img-thumbnail" src="assets/image/sen40.png" title="40%"
                [style]="CSSLoHangBinhChon40" />
            <h5 class="text-center"><b>40%</b></h5>
        </div>
        <div class="col-lg-2 col-sm-4 col-4">
            <img (click)="LoHangBinhChonAdd(60)" class="img-thumbnail" src="assets/image/sen60.png" title="60%"
                [style]="CSSLoHangBinhChon60" />
            <h5 class="text-center"><b>60%</b></h5>
        </div>
        <div class="col-lg-2 col-sm-4 col-4">
            <img (click)="LoHangBinhChonAdd(80)" class="img-thumbnail" src="assets/image/sen80.png" title="80%"
                [style]="CSSLoHangBinhChon80" />
            <h5 class="text-center"><b>80%</b></h5>
        </div>
        <div class="col-lg-2 col-sm-4 col-4">
            <img (click)="LoHangBinhChonAdd(100)" class="img-thumbnail" src="assets/image/sen100.png" title="100%"
                [style]="CSSLoHangBinhChon100" />
            <h5 class="text-center"><b>100%</b></h5>
        </div>
        <div class="col-lg-2 col-sm-4 col-4">
            <img (click)="LoHangBinhChonAdd(200)" class="img-thumbnail" src="assets/image/sen200.png" title="100%"
                [style]="CSSLoHangBinhChon200" />
            <h5 class="text-center"><b>200%</b></h5>
        </div>
    </div>
    <hr />
    <div class="row"
        style="border-color: #e4e4e4; border-style: solid; border-width: 1px; margin: 0px; padding-bottom: 20px;">
        <div class="col-lg-12 col-sm-12 col-12 bg-primary text-light" style="cursor: pointer;">
            <h5 class="text-center" style="padding: 5px; padding-top: 12px;">
                Tra cứu Mã Truy Xuất Nguồn Gốc Hàng Hóa và Carbon
                Footprint
            </h5>
        </div>
        <div class="col-lg-4 col-sm-12 col-12">
            <br />
            <div class="form-group">
                <input name="MaSo" [(ngModel)]="TemPhieuService.BaseParameter.MaSo"
                    placeholder="Nhập Mã số truy vết sản phẩm ..." type="text" class="form-control">
            </div>
            <br />
            <div class="form-group">
                <a (click)="TemPhieuSearch001()" style="width: 100%; color: #ffffff;" title="Mã số truy vết sản phẩm"
                    class="btn btn-primary"><i class="bi bi-search"></i> Tra cứu Mã số truy vết sản phẩm</a>
            </div>
        </div>
        <div class="col-lg-4 col-sm-12 col-12">
            <br />
            <div class="form-group">
                <input name="VanChuyenGTIN" [(ngModel)]="TemPhieuService.BaseParameter.VanChuyenGTIN"
                    placeholder="Nhập Mã GTIN ..." type="text" class="form-control">
            </div>
            <br />
            <div class="form-group">
                <a (click)="TemPhieuSearch002()" style="width: 100%; color: #ffffff;" title="Mã GTIN"
                    class="btn btn-primary"><i class="bi bi-search"></i> Tra cứu Mã GTIN</a>
            </div>
        </div>
        <div class="col-lg-4 col-sm-12 col-12">
            <br />
            <div class="form-group">
                <input name="MaLoHang" [(ngModel)]="TemPhieuService.BaseParameter.MaLoHang"
                    placeholder="Nhập Mã lô đóng gói hàng hóa ..." type="text" class="form-control">
            </div>
            <br />
            <div class="form-group">
                <a (click)="TemPhieuSearch003()" style="width: 100%; color: #ffffff;" title="Mã lô đóng gói hàng hóa"
                    class="btn btn-primary"><i class="bi bi-search"></i> Tra cứu Mã lô đóng gói hàng hóa</a>
            </div>
        </div>
    </div>
</div>
<footer class="bg-success" style="padding: 10px; padding-top: 20px;">
    <h6 class="text-center text-light">Hệ thống truy xuất nguồn gốc và vết chân Carbon ứng dụng Blockchain & AI
    </h6>
    <h6 class="text-center text-light">
        <a href="https://carbonunit.net/" title="https://carbonunit.net/" target="_blank"
            style="text-decoration: none; color:aqua;">Carbon Footprint Traceability System @ 2VN & CFT by Digital
            Kingdom JSC</a>
    </h6>
</footer>
<div style="position: fixed; bottom: 160px; right: 10px;">
    <a href="{{URLVN}}" target="_blank"><img class="img-thumbnail" src="assets/image/vn.png" width="45" height="30"
            style="margin-bottom: 5px;" /></a>
    <br />
    <a href="{{URLCN}}" target="_blank"><img class="img-thumbnail" src="assets/image/cn.png" width="45" height="30"
            style="margin-bottom: 5px;" /></a>
    <br />
    <a href="{{URLEN}}" target="_blank"><img class="img-thumbnail" src="assets/image/en.png" width="45" height="30"
            style="margin-bottom: 5px;" /></a>
    <br />
    <a href="{{URLJP}}" target="_blank"><img class="img-thumbnail" src="assets/image/jp.png" width="45" height="30"
            style="margin-bottom: 5px;" /></a>
    <br />
    <a href="{{URLKR}}" target="_blank"><img class="img-thumbnail" src="assets/image/kr.png" width="45" height="30"
            style="margin-bottom: 5px;" /></a>
    <br />
    <a href="{{URLFR}}" target="_blank"><img class="img-thumbnail" src="assets/image/fr.png" width="45" height="30"
            style="margin-bottom: 5px;" /></a>
</div>
<app-loading *ngIf="TemPhieuService.IsShowLoading"></app-loading>