export const environment = {
  production: false,
  DomainURL: "",
  DomainDestination: "",  
  APIURL: "https://api.cms.cft.carbonunit.net/api/v1/",
  APIRootURL: "https://api.cms.cft.carbonunit.net/",
  APIUploadURL: "https://api.upload.cft.carbonunit.net/api/v1/",
  APIUploadRootURL: "https://api.upload.cft.carbonunit.net/",
  LoginURL: "https://login.cft.carbonunit.net/",
  Website: "https://cft.carbonunit.net/",  
  IPRegistry: "https://ipv4.myexternalip.com/json",
  IPRegistryURL: "https://api.ipregistry.co/?key=tryout",
  LoadingFile: "loading.gif",
  DialogConfigWidth: "80%",
  DialogConfigWidth60: "60%",
  InitializationString: "",
  InitializationNumber: 0,  
  DanhMucQuocGiaIDVietNam: 237,  
  DanhMucUngDungID: 6,  
  MapZoom: 8,
  Latitude: 10.235012,
  Longitude: 106.3797368,
  PageSize: 10,
  MaptilerAPIKey: "6iFTqps4QVACLERa26MA",
  TokenDefault: "d85df3cb-5688-4b1d-b120-50479b23d2a0",
  Token: "Token",
  TokenFCM: "TokenFCM",
  MaSo: "00000000",
  QRCode: "QRCode",
  LoHangInfoByQRCode: "LoHangInfoByQRCode",
  TraceabilitySite: "https://traceability.blockchain.carbonunit.net",
  TraceabilityTranslateSite: "https://traceability-blockchain-carbonunit-net",
  Homepage: "Homepage",
  Login: "Login",
  Empty: "Empty",
  Bearer: "Bearer null",
  ThanhVienToChucID: "ThanhVienToChucID",
  ThanhVienParentID: "ThanhVienParentID",
  ThanhVienID: "ThanhVienID",
  ThanhVienHoTen: "ThanhVienHoTen",
  ThanhVienTaiKhoan: "ThanhVienTaiKhoan",
  ThanhVienFileName: "ThanhVienFileName",
  UploadSuccess: "Upload thành công.",
  UploadNotSuccess: "Upload không thành công.",
  SaveSuccess: "Lưu thành công.",
  SaveNotSuccess: "Lưu không thành công.",
  DeleteConfirm: "Bạn muốn xóa dữ liệu này?",
  DeleteSuccess: "Xóa thành công.",
  DeleteNotSuccess: "Xóa không thành công.",
  LoginNotSuccess: "Đăng nhập không thành công.",
  UserNameExists: "Tài khoản đã tồn tại.",
  UserNameRequired: "UserName là bắt buộc.",
  PageTitle: "Cổng truy xuất nguồn gốc sản phẩm",
  PageDescription: "Truy xuất nguồn gốc",
  NhaCungCapChuaCapNhat: "Nhà cung cấp chưa cập nhật",
  IPRegistryIP: "IPRegistryIP",
  IPRegistryDevice: "IPRegistryDevice",
  IPRegistryLongitude: "IPRegistryLongitude",
  IPRegistryLatitude: "IPRegistryIPLatitude",
  IPRegistryCountryName: "IPRegistryCountryName",
  IPRegistryRegionName: "IPRegistryRegionName",  
};
