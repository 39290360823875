import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoHang } from 'src/app/shared/LoHang.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class LoHangService extends BaseService{

    DisplayColumns001: string[] = ['ID', 'ParentID', 'Name', 'DangKy'];
        
    List: LoHang[] | undefined;
    ListFilter: LoHang[] | undefined;
    FormData!: LoHang;
    

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "LoHang";
    }    
    GetBySearchString_BatDau_KetThucToListAsync() {
        let url = this.APIURL + this.Controller + '/GetBySearchString_BatDau_KetThucToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
    GetByTypeNameAndActiveAsync() {
        let url = this.APIURL + this.Controller + '/GetByTypeNameAndActiveAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
}

